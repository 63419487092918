import * as adminAdminRoleActions from "../../../store/admin/actions/adminAdminRoleActions";
import AdminRoleDeleteConfirmation from "./AdminRoleDeleteConfirmation";
import AdminRoleOverwriteConfirmation from "./AdminRoleOverwriteConfirmation";
import AdminRoleRename from "./AdminRoleRename";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ManageAdminRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            mode: this.props.mode,
            adminRoleName: "",
            adminRoleNameInvalid: true,
            adminRoleNameTouched: false,
            pageAssignments: null,
            adminRoles: [],
            showDialogOverwriteConfirmationTs: null,
            showDialogDeleteConfirmationTs: null,
            showDialogRenameTs: null,
        };
    }

    componentDidMount() {
        this.initData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.initData();
        }

        if (prevProps.mode !== this.props.mode) {
            this.setState({ mode: this.props.mode });
        }

        if (prevProps.pageAssignments !== this.props.pageAssignments) {
            this.setState({ pageAssignments: this.props.pageAssignments });
        }

        if (prevProps.adminRoles !== this.props.adminRoles) {
            this.setState({ adminRoles: this.props.adminRoles });
        }
    }

    initData = () => {
        this.setState({ loading: true });
        this.props.onAdminRole.getAdminRoles().then(() => {
            if (!this.props.loading) {
                this.setState({ adminRoles: this.props.adminRoles });
            }

            this.setState({ loading: this.props.loading });
        });
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        if (showDialog) {
            this.setState({ adminRoleName: "", adminRoleNameInvalid: true, adminRoleNameTouched: false });
            this.props.reopenPageAssignment();
        }
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ adminRoleNameTouched: true, adminRoleName: value, adminRoleNameInvalid: !value || value == "" });
    };

    handlePerformSave = () => {
        const { pageAssignments, adminRoleName } = this.state;
        this.setState({ isUpdating: true });
        this.props.onAdminRole
            .createAdminRole({
                name: adminRoleName,
                pageAssignments: pageAssignments,
            })
            .then(() => {
                if (!this.props.isUpdating) {
                    if (this.props.error) {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        this.props.generateAlert("success", "Add new admin role success");
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
    };

    handleLoadViewAdminRole = (mode, adminRole) => {
        this.setState({ isUpdating: true });
        this.props.onAdminRole.getAdminRolePageAssignments(adminRole.id).then(() => {
            if (!this.props.loading) {
                this.setState({ adminRoleName: "", adminRoleNameInvalid: true, adminRoleNameTouched: false, showDialog: false });
                if (mode == "Load") {
                    this.props.handleLoadAdminRole(adminRole.name, this.props.adminRolePageAssignments);
                } else {
                    this.props.handleViewAdminRole(adminRole.name, this.props.adminRolePageAssignments);
                }
            }
            this.setState({ isUpdating: this.props.loading });
        });
    };

    reopenManageAdminRole = () => {
        this.setState({ showDialog: true });
    };

    handleOverwrite = (adminRole) => {
        this.setState({ selectedAdminRole: adminRole, showDialogOverwriteConfirmationTs: new Date(), showDialog: false });
    };

    handleConfirmOverwrite = () => {
        const { selectedAdminRole, pageAssignments } = this.state;
        this.setState({ showDialog: true, isUpdating: true });
        this.props.onAdminRole
            .updateAdminRolePageAssignments(selectedAdminRole.id, {
                pageAssignments,
            })
            .then(() => {
                if (!this.props.isUpdating) {
                    if (this.props.error) {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        this.props.generateAlert("success", "Overwrite admin role success");
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
    };

    handleDelete = (adminRole) => {
        this.setState({ selectedAdminRole: adminRole, showDialogDeleteConfirmationTs: new Date(), showDialog: false });
    };

    handleConfirmDelete = () => {
        const { selectedAdminRole } = this.state;
        this.setState({ showDialog: true, isUpdating: true });
        this.props.onAdminRole.deleteAdminRole(selectedAdminRole.id).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.setState({ adminRoles: this.props.adminRoles });
                    this.props.generateAlert("success", "Delete admin role success");
                }
            }
            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleRename = (adminRole) => {
        this.setState({ selectedAdminRole: adminRole, showDialogRenameTs: new Date(), showDialog: false });
    };

    handleConfirmRename = (adminRoleName) => {
        const { selectedAdminRole } = this.state;
        this.setState({ showDialog: true, isUpdating: true });
        this.props.onAdminRole.renameAdminRole(selectedAdminRole.id, adminRoleName).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                    this.setState({ showDialogRenameTs: new Date(), showDialog: false });
                } else {
                    this.setState({ adminRoles: this.props.adminRoles });
                    this.props.generateAlert("success", "Rename admin role success");
                }
            }
            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const maxAdminRoles = 8;
        const { mode, adminRoleName, adminRoleNameTouched, adminRoleNameInvalid, adminRoles, selectedAdminRole } = this.state;

        return (
            <React.Fragment>
                <AdminRoleOverwriteConfirmation showDialog={this.state.showDialogOverwriteConfirmationTs} generateAlert={this.props.generateAlert} reopenManageAdminRole={this.reopenManageAdminRole} handleConfirmOverwrite={this.handleConfirmOverwrite}></AdminRoleOverwriteConfirmation>
                <AdminRoleDeleteConfirmation showDialog={this.state.showDialogDeleteConfirmationTs} generateAlert={this.props.generateAlert} reopenManageAdminRole={this.reopenManageAdminRole} handleConfirmDelete={this.handleConfirmDelete}></AdminRoleDeleteConfirmation>
                <AdminRoleRename showDialog={this.state.showDialogRenameTs} generateAlert={this.props.generateAlert} reopenManageAdminRole={this.reopenManageAdminRole} handleConfirmRename={this.handleConfirmRename} adminRoleName={selectedAdminRole ? selectedAdminRole.name : ""}></AdminRoleRename>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="lg">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}></ModalHeader>
                    <ModalBody>
                        <Form>
                            {mode == "Save Admin Role" && (
                                <Row>
                                    <Col md={12}>
                                        <FormGroup row>
                                            <Label sm={6}>Enter New Admin Role Name:</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="text"
                                                    name="adminRoleName"
                                                    id="adminRoleName"
                                                    value={adminRoles && adminRoles.length >= maxAdminRoles ? `You already have the maximum ${maxAdminRoles} roles` : adminRoleName}
                                                    invalid={adminRoleNameTouched && adminRoleNameInvalid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating || (adminRoles && adminRoles.length >= maxAdminRoles)}
                                                    maxLength={30}
                                                    placeholder="Enter Name (Max 30 chars)"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                        {adminRoles.map((adminRole, index) => (
                            <Row key={index} style={{ paddingBottom: "10px" }}>
                                <Col md="6">{adminRole.name}</Col>
                                <Col md="6" style={{ textAlign: "right" }}>
                                    {mode == "Save Admin Role" ? (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.handleOverwrite(adminRole);
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Overwrite
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.handleRename(adminRole);
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Rename
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                color="danger"
                                                onClick={() => {
                                                    this.handleDelete(adminRole);
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Delete
                                            </Button>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.handleLoadViewAdminRole("View", adminRole);
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                View
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                color="success"
                                                onClick={() => {
                                                    this.handleLoadViewAdminRole("Load", adminRole);
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Load
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            {mode == "Save Admin Role" && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handlePerformSave();
                                    }}
                                    disabled={this.state.isUpdating || adminRoleNameInvalid || (adminRoles && adminRoles.length >= maxAdminRoles)}
                                >
                                    Save
                                </Button>
                            )}
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.adminAdminRole.error,
        loading: state.adminAdminRole.loading,
        isUpdating: state.adminAdminRole.isUpdating,
        adminRoles: state.adminAdminRole.adminRoles,
        adminRolePageAssignments: state.adminAdminRole.adminRolePageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAdminRole: bindActionCreators(adminAdminRoleActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdminRole);
