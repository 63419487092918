import * as actionPlanActions from "../../store/actions/actionPlanAction";
import * as categoryActions from "../../store/actions/categoryAction";
import * as companyActions from "../../store/actions/companyAction";
import * as jobActions from "../../store/actions/jobAction";
import * as skillProfileActions from "../../store/actions/skillProfileAction";
import changeInput from "../../utils/changeInput";
import { generateRandomString, parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import { faCircle as farCircle } from "@fortawesome/free-regular-svg-icons";
import { faAdjust, faCheck, faCircle, faExclamationCircle, faInfoCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import Combobox from "react-widgets/Combobox";
import { Button, ButtonGroup, Col, Container, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const containerStyle = {
    fontSize: "11px",
    fontFamily: "IBMPlexSans-Light",
};

const titleStyle = {
    fontSize: "13px",
    fontFamily: "IBMPlexSans-Bold",
};

class SkillStep extends React.Component {
    static displayName = SkillStep.name;

    constructor(props) {
        super(props);
        this.state = {
            optionsStatus: [
                { label: "Todo", value: "ToDo" },
                { label: "In Progress", value: "InProgress" },
                { label: "Done", value: "Done - self verified" },
            ],
            loading: true,
            loadingFull: false,
            actions: [],
            poppedUpAction: null,
            showActionPlanDetail: false,
            optionsActionSkills: [],
            showActionPlanEdit: false,
            editedAction: null,
            form: {
                id: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                status: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                actionType: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                name: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                targetCompletionOn: {
                    validation: {
                        required: this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false,
                        isDateTime: true,
                    },
                    value: null,
                    valid: !(this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false),
                    isValidFormat: true,
                    touched: false,
                },
                description: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                link: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                duration: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            selectedJob: null,
            optionsJob: [],
            jobLorProfiles: [],
            jobSkillProfiles: [],
            selectedSkills: [],
            pickedOtherSkills: [],
            selectedOtherSkill: "",
            selectedOtherSkills: [],
            actionPlanUpdating: false,
        };

        this.initData = this.initData.bind(this);
        this.handleSkillCheckedChange = this.handleSkillCheckedChange.bind(this);
        this.handleChangeOtherSkill = this.handleChangeOtherSkill.bind(this);
        this.handleAddOtherSkill = this.handleAddOtherSkill.bind(this);
        this.handleOtherSkillCheckedChange = this.handleOtherSkillCheckedChange.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
        this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
        this.generateRandomString = generateRandomString.bind(this);
    }

    componentDidMount() {
        this.initData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newActionPlanAdded !== this.props.newActionPlanAdded && this.props.newActionPlanAdded) {
            this.refreshActions();
        }
    }

    refreshActions = () => {
        Promise.all([this.props.managedPeople ? this.props.onActionPlan.getManagedPeopleActions(this.props.managedPeople.personB.email) : this.props.onActionPlan.getMyActions()])
            .then(() => {
                if (!this.props.loading) {
                    if (this.props.error) {
                        this.setState({ loading: false });
                    } else {
                        const actions = this.props.actions;
                        this.setState({ actions });

                        if (actions && actions.length > 0) {
                            let selectedJob = null;

                            const jobMatches = this.props.jobMatches;
                            const currentJob = jobMatches.find((it) => it.jobType === "Current");
                            const futureJob = jobMatches.find((it) => it.jobType === "Future");

                            if (currentJob) {
                                selectedJob = currentJob;
                            } else if (futureJob) {
                                selectedJob = futureJob;
                            }

                            const actionSkills = this.props.actionSkills;

                            let categories = this.props.categories;
                            let jobLorProfiles = this.props.jobLorProfiles;
                            let jobSkillProfiles = this.props.jobSkillProfiles;

                            const lorProfiles = this.props.lorProfiles;
                            const skillProfiles = this.props.skillProfiles;

                            categories = categories.map((category) => {
                                return {
                                    id: category.id,
                                    name: category.name,
                                    description: category.description,
                                    colour: category.colour,
                                    sfiaVersion: category.sfiaVersion,
                                    subCategories: category.subCategories.map((subCategory) => {
                                        return {
                                            id: subCategory.id,
                                            name: subCategory.name,
                                            description: subCategory.description,
                                            colour: subCategory.colour,
                                            skillColour: subCategory.skillColour,
                                            skills: subCategory.skills.map((skill) => {
                                                let skillNameShown = false;

                                                return {
                                                    id: skill.id,
                                                    name: skill.name,
                                                    skillsCode: skill.skillsCode,
                                                    description: skill.description,
                                                    levels: skill.levels.map((level) => {
                                                        const skillProfile = skillProfiles.find((x) => x.skillCode == skill.skillsCode && x.level == level.level);
                                                        const jobSkillProfile = selectedJob ? jobSkillProfiles.find((x) => x.jobId == selectedJob.id && x.skillCode == skill.skillsCode && x.level == level.level) : null;

                                                        if (jobSkillProfile && skillProfile) {
                                                            if (skillProfile.numericVal == 1 || skillProfile.stringVal == "M") {
                                                                jobSkillProfile.actioned = true;
                                                            }
                                                        }

                                                        const action = actions.find((actions) => actions.skills.some((it) => it.code == skill.skillsCode && it.level == level.level));

                                                        level = {
                                                            skillNameShown: false,
                                                            show: false,
                                                            id: level.id,
                                                            level: level.level,
                                                            description: level.description,
                                                            skillProfile: skillProfile,
                                                            jobSkillProfile: jobSkillProfile,
                                                            action: action,
                                                        };

                                                        if (!skillNameShown) {
                                                            if ((level.skillProfile && level.jobSkillProfile) || (!level.skillProfile && level.jobSkillProfile) || this.state.showAllSkill) {
                                                                level.skillNameShown = true;
                                                                skillNameShown = true;
                                                            }
                                                        }

                                                        if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < 1) || (!level.skillProfile && level.jobSkillProfile)) {
                                                            level.show = true;
                                                        }

                                                        return level;
                                                    }),
                                                };
                                            }),
                                        };
                                    }),
                                };
                            });

                            jobLorProfiles.map((jobLorProfile) => {
                                const lorProfile = lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode && it.level >= jobLorProfile.level);
                                const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobLorProfile.lorCode && skill.level >= jobLorProfile.level));
                                if (lorProfile || plan) {
                                    jobLorProfile.actioned = true;
                                }
                            });

                            jobSkillProfiles.map((jobSkillProfile) => {
                                const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobSkillProfile.skillCode && skill.level == jobSkillProfile.level));
                                if (plan) {
                                    jobSkillProfile.actioned = true;
                                }
                            });

                            actionSkills.forEach((actionSkill) => {
                                actionSkill.skills.forEach((skill) => {
                                    if (
                                        actions.filter((x) => x.skills.some((y) => y.code == skill.skillCode && y.level == skill.skillLevel)).length > 0 ||
                                        jobLorProfiles.filter((it) => it.lorCode == skill.skillCode && it.level == skill.skillLevel).length > 0 ||
                                        jobSkillProfiles.filter((it) => it.skillCode == skill.skillCode && it.level == skill.skillLevel).length > 0
                                    ) {
                                        skill.actioned = true;
                                    }
                                });
                            });

                            if (selectedJob) {
                                jobLorProfiles = jobLorProfiles ? jobLorProfiles.filter((x) => x.jobId == selectedJob.id) : [];
                                jobSkillProfiles = jobSkillProfiles ? jobSkillProfiles.filter((x) => x.jobId == selectedJob.id) : [];
                            }

                            this.setState({
                                categories,
                                optionsActionTypes: this.props.actionTypes,
                                optionsActionSkills: actionSkills,
                                jobLorProfiles,
                                jobSkillProfiles,
                                lorProfiles,
                                skillProfiles,
                            });
                        }
                    }
                }

                this.setState({ loading: this.props.loading });
            })
            .catch((err) => {
                console.log("err", err);
                this.setState({ loading: false });
            });
    };

    initData() {
        this.setState({ pickedOtherSkills: [], selectedOtherSkills: [], loadingFull: true });

        Promise.all([
            this.props.onActionPlan.getActionTypes(),
            this.props.onActionPlan.getActionSkills(),
            this.props.onCompany.getSelectedProviderCompanies(),
            this.props.managedPeople ? this.props.onActionPlan.getManagedPeopleActions(this.props.managedPeople.personB.email) : this.props.onActionPlan.getMyActions(),
        ])
            .then(() => {
                if (!this.props.loading && !this.props.companyLoading) {
                    if (this.props.error) {
                        this.setState({ loading: false });
                    } else {
                        const actions = this.props.actions;
                        this.setState({ actions });

                        if (actions && actions.length > 0) {
                            Promise.all([
                                //this.props.managedPeople ? this.props.onJob.getManagedPeopleJobMatches(this.props.managedPeople.personB.email) : this.props.onJob.getMyJobMatches(),
                                this.props.managedPeople ? this.props.onJob.getManagedPeopleJobLorProfiles(this.props.managedPeople.personB.email) : this.props.onJob.getJobLorProfiles(null),
                                this.props.managedPeople ? this.props.onJob.getManagedPeopleJobSkillProfiles(this.props.managedPeople.personB.email) : this.props.onJob.getJobSkillProfiles(null),
                            ])
                                .then(() => {
                                    if (
                                        !this.props.jobLoading
                                        //&& !this.props.jobMatchesLoading
                                    ) {
                                        let optionsJob = [];
                                        let selectedJob = null;

                                        const jobMatches = this.props.jobMatches;
                                        const currentJob = jobMatches.find((it) => it.jobType === "Current");
                                        const futureJob = jobMatches.find((it) => it.jobType === "Future");

                                        if (currentJob) {
                                            optionsJob.push({
                                                value: currentJob.jobType + " - " + currentJob.jobTitle,
                                                label: currentJob.jobType + " - " + currentJob.jobTitle,
                                                type: currentJob.jobType,
                                                id: currentJob.jobId,
                                            });
                                        }

                                        if (futureJob) {
                                            optionsJob.push({
                                                value: futureJob.jobType + " - " + futureJob.jobTitle,
                                                label: futureJob.jobType + " - " + futureJob.jobTitle,
                                                type: futureJob.jobType,
                                                id: futureJob.jobId,
                                            });
                                        }

                                        if (optionsJob.length > 0) {
                                            selectedJob = optionsJob[0];
                                        }

                                        const actionSkills = this.props.actionSkills;

                                        let categories = this.props.categories;

                                        let jobLorProfiles = this.props.jobLorProfiles; // ? this.props.jobLorProfiles.filter(x => x.jobId == selectedJob.id) : [];
                                        let jobSkillProfiles = this.props.jobSkillProfiles; // ? this.props.jobSkillProfiles.filter(x => x.jobId == selectedJob.id) : [];

                                        Promise.all([
                                            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleLorProfile(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMyLorProfile(null, null, null),
                                            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleSkillProfile(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMySkillProfile(null, null, null),
                                        ])
                                            .then(() => {
                                                if (!this.props.loadingProfile && !this.props.loadingLor) {
                                                    if (this.props.errorLor || this.props.categoryError) {
                                                        this.setState({ loading: false });
                                                    } else {
                                                        const lorProfiles = this.props.lorProfiles;
                                                        const skillProfiles = this.props.skillProfiles;

                                                        categories = categories.map((category) => {
                                                            return {
                                                                id: category.id,
                                                                name: category.name,
                                                                description: category.description,
                                                                colour: category.colour,
                                                                sfiaVersion: category.sfiaVersion,
                                                                subCategories: category.subCategories.map((subCategory) => {
                                                                    return {
                                                                        id: subCategory.id,
                                                                        name: subCategory.name,
                                                                        description: subCategory.description,
                                                                        colour: subCategory.colour,
                                                                        skillColour: subCategory.skillColour,
                                                                        skills: subCategory.skills.map((skill) => {
                                                                            let skillNameShown = false;

                                                                            return {
                                                                                id: skill.id,
                                                                                name: skill.name,
                                                                                skillsCode: skill.skillsCode,
                                                                                description: skill.description,
                                                                                levels: skill.levels.map((level) => {
                                                                                    const skillProfile = skillProfiles.find((x) => x.skillCode == skill.skillsCode && x.level == level.level);
                                                                                    const jobSkillProfile = selectedJob ? jobSkillProfiles.find((x) => x.jobId == selectedJob.id && x.skillCode == skill.skillsCode && x.level == level.level) : null;

                                                                                    if (jobSkillProfile && skillProfile) {
                                                                                        if (skillProfile.numericVal == 1 || skillProfile.stringVal == "M") {
                                                                                            jobSkillProfile.actioned = true;
                                                                                        }
                                                                                    }

                                                                                    const action = actions.find((actions) => actions.skills.some((it) => it.code == skill.skillsCode && it.level == level.level));

                                                                                    level = {
                                                                                        skillNameShown: false,
                                                                                        show: false,
                                                                                        id: level.id,
                                                                                        level: level.level,
                                                                                        description: level.description,
                                                                                        skillProfile: skillProfile,
                                                                                        jobSkillProfile: jobSkillProfile,
                                                                                        action: action,
                                                                                    };

                                                                                    if (!skillNameShown) {
                                                                                        if ((level.skillProfile && level.jobSkillProfile) || (!level.skillProfile && level.jobSkillProfile) || this.state.showAllSkill) {
                                                                                            level.skillNameShown = true;
                                                                                            skillNameShown = true;
                                                                                        }
                                                                                    }

                                                                                    if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < 1) || (!level.skillProfile && level.jobSkillProfile)) {
                                                                                        level.show = true;
                                                                                    }

                                                                                    return level;
                                                                                }),
                                                                            };
                                                                        }),
                                                                    };
                                                                }),
                                                            };
                                                        });

                                                        jobLorProfiles.map((jobLorProfile) => {
                                                            const lorProfile = lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode && it.level >= jobLorProfile.level);
                                                            const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobLorProfile.lorCode && skill.level >= jobLorProfile.level));
                                                            if (lorProfile || plan) {
                                                                jobLorProfile.actioned = true;
                                                            }
                                                        });

                                                        jobSkillProfiles.map((jobSkillProfile) => {
                                                            const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobSkillProfile.skillCode && skill.level == jobSkillProfile.level));
                                                            if (plan) {
                                                                jobSkillProfile.actioned = true;
                                                            }
                                                        });

                                                        actionSkills.forEach((actionSkill) => {
                                                            actionSkill.skills.forEach((skill) => {
                                                                if (
                                                                    actions.filter((x) => x.skills.some((y) => y.code == skill.skillCode && y.level == skill.skillLevel)).length > 0 ||
                                                                    jobLorProfiles.filter((it) => it.lorCode == skill.skillCode && it.level == skill.skillLevel).length > 0 ||
                                                                    jobSkillProfiles.filter((it) => it.skillCode == skill.skillCode && it.level == skill.skillLevel).length > 0
                                                                ) {
                                                                    skill.actioned = true;
                                                                }
                                                            });
                                                        });

                                                        if (selectedJob) {
                                                            jobLorProfiles = jobLorProfiles ? jobLorProfiles.filter((x) => x.jobId == selectedJob.id) : [];
                                                            jobSkillProfiles = jobSkillProfiles ? jobSkillProfiles.filter((x) => x.jobId == selectedJob.id) : [];
                                                        }

                                                        this.setState({
                                                            categories,
                                                            optionsActionTypes: this.props.actionTypes,
                                                            optionsActionSkills: actionSkills,
                                                            jobLorProfiles,
                                                            jobSkillProfiles,
                                                            lorProfiles,
                                                            skillProfiles,
                                                        });
                                                    }
                                                }

                                                this.setState({
                                                    loadingFull: this.props.loadingProfile || this.props.loadingLor,
                                                });
                                            })
                                            .catch((err) => {
                                                console.log("err", err);
                                                this.setState({ loadingFull: false });
                                            });
                                    }
                                })
                                .catch((err) => {
                                    console.log("err", err);
                                    this.setState({ loading: false });
                                });
                        }
                    }
                }

                this.setState({
                    loading: this.props.loading || this.props.companyLoading,
                });
            })
            .catch((err) => {
                console.log("err", err);
                this.setState({ loading: false });
            });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeDate = (name, value) => {
        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    renderEmptyAction(x) {
        let rows = [];
        for (var i = 0; i < x; i++) {
            rows.push(
                <Row key={i} className="align-items-center mb-2">
                    <Col xs="auto pr-0">
                        <FontAwesomeIcon className="d-block mx-auto" icon={faCircle} style={{ fontSize: "20px", color: "#cccccc" }} />
                    </Col>
                    <Col className="p-0">
                        <p className="ml-2 mb-0 color-dark">
                            <ContentTranslator page="SfiaProfile" name="OutstandingActionNoAction" contentText="No more actions for now" />
                        </p>
                    </Col>
                </Row>
            );
        }
        return <React.Fragment>{rows}</React.Fragment>;
    }

    openActionDetail = (action) => {
        this.setState({ poppedUpAction: action, showActionPlanDetail: true });
    };

    togleActionPlanDetailModal = () => {
        const showActionPlanDetail = this.state.showActionPlanDetail;
        this.setState({ showActionPlanDetail: !showActionPlanDetail });
    };

    handleHideAction = (action) => {
        this.setState({ actionPlanUpdating: true });
        this.props.onActionPlan.updateHideAction(action.id).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    //this.props.generateAlert("danger", this.props.actionPlanUpdatingError);
                } else {
                    this.setState({ showActionPlanEdit: false });
                    this.togleActionPlanDetailModal();
                    this.initData();
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    };

    modalActionPlanDetail() {
        const { poppedUpAction } = this.state;

        return (
            <Modal isOpen={this.state.showActionPlanDetail} toggle={!this.state.actionPlanUpdating && this.togleActionPlanDetailModal} size="lg">
                <ModalHeader toggle={!this.state.actionPlanUpdating && this.togleActionPlanDetailModal}>
                    <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoTitle" contentText="Action Info" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoType" contentText="Type" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.actionType} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoName" contentText="Name" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.name} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoTarget" contentText="Target" />
                            </Label>
                            <Col sm={9}>
                                <Input
                                    value={
                                        poppedUpAction &&
                                        poppedUpAction.targetCompletionOn &&
                                        new Intl.DateTimeFormat("en-GB", {
                                            year: "2-digit",
                                            month: "short",
                                            day: "numeric",
                                        }).format(new Date(poppedUpAction.targetCompletionOn))
                                    }
                                    disabled
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoDescription" contentText="Description" />
                            </Label>
                            <Col sm={9}>
                                <Input type="textarea" value={poppedUpAction && poppedUpAction.description} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoWebsiteLink" contentText="Website" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.link} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoDuration" contentText="Duration" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.duration} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoSkills" contentText="Skills" />
                            </Label>
                            <Label sm={9}>
                                {poppedUpAction &&
                                    poppedUpAction.skills &&
                                    poppedUpAction.skills.map((it, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {`${it.code}-${it.level}`}
                                                    {index < poppedUpAction.skills.length - 1 && ","}
                                                </span>{" "}
                                            </React.Fragment>
                                        );
                                    })}
                            </Label>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div style={{ width: "100%" }}>
                        <div style={{ float: "left" }}>
                            {!this.props.managedPeople && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleHideAction(poppedUpAction);
                                    }}
                                    disabled={this.state.loadingFull || this.state.actionPlanUpdating}
                                >
                                    Hide
                                </Button>
                            )}
                        </div>
                        <div style={{ float: "right" }}>
                            {(!this.props.managedPeople || (this.props.managedPeople && this.props.managedPeople.relationshipType == "Line Manager" ? true : false)) && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.togleActionPlanDetailModal();
                                        this.openActionPlanEditModal(poppedUpAction);
                                    }}
                                    disabled={this.state.loadingFull || this.state.actionPlanUpdating}
                                >
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoButtonEdit" contentText="Edit" />
                                </Button>
                            )}
                            &nbsp;
                            <Button color="secondary" onClick={this.togleActionPlanDetailModal} disabled={this.state.actionPlanUpdating}>
                                <ContentTranslator page="SfiaProfile" name="OutstandingActionInfoButtonClose" contentText="Close" />
                            </Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    openActionPlanEditModal = (action) => {
        let form = this.state.form;

        form.id.value = action.id;
        form.id.valid = true;
        form.id.isValidFormat = true;

        form.status.value = action.status;
        form.status.valid = true;
        form.status.isValidFormat = true;

        form.actionType.value = action.actionType;
        form.actionType.valid = true;
        form.actionType.isValidFormat = true;

        form.name.value = action.name;
        form.name.valid = true;
        form.name.isValidFormat = true;

        form.targetCompletionOn.value = action.targetCompletionOn;
        form.targetCompletionOn.valid = form.targetCompletionOn.validation.required ? (action.targetCompletionOn ? true : false) : true;
        form.targetCompletionOn.isValidFormat = form.targetCompletionOn.validation.required ? (action.targetCompletionOn ? true : false) : true;

        form.description.value = action.description;
        form.link.value = action.link;
        form.duration.value = action.duration;

        this.setState({
            editedAction: action,
            showActionPlanEdit: true,
            form,
            isFormValid: form.targetCompletionOn.valid,
            selectedSkills: action.skills.map((skill) => {
                return {
                    id: skill.id,
                    skillName: skill.name,
                    skillCode: skill.code,
                    skillLevel: skill.level,
                    skillType: skill.type,
                };
            }),
        });
    };

    togleActionPlanEditModal = () => {
        const showActionPlanEdit = this.state.showActionPlanEdit;
        this.setState({ showActionPlanEdit: !showActionPlanEdit });
    };

    modalEditActionPlan() {
        const { form, editedAction, optionsActionTypes, optionsActionSkills, selectedSkills, pickedOtherSkills, selectedOtherSkills, jobLorProfiles, jobSkillProfiles } = this.state;
        const { lors, categories, showHiddenSkills } = this.props;

        const availableLorCodes = lors
            ? lors
                  .filter((it) => !it.isHidden)
                  .flatMap((lor, index) => {
                      return lor.lorCode;
                  })
            : [];

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        let optionsStatus = [
            { label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusTodo", "Todo"), value: "ToDo" },
            { label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusInProgress", "In Progress"), value: "InProgress" },
            { label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusDone", "Done"), value: "Done - self verified" },
        ];

        if (editedAction && editedAction.interventionId && editedAction.actionType == "Accredited Badge Issuing Action" && editedAction.status == "IssueBadge" && !optionsStatus.find((it) => it.value == "IssueBadge")) {
            optionsStatus.push({ label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusIssueBadge", "Issue Badge"), value: "IssueBadge" });
        }

        let editedOptionsActionTypes = optionsActionTypes ? [...optionsActionTypes] : [];

        if (editedAction && !editedOptionsActionTypes.find((it) => it.name == editedAction.actionType)) {
            editedOptionsActionTypes.push({
                name: editedAction.actionType,
            });
        }

        let spreadedActionSkills = [];
        if (optionsActionSkills) {
            spreadedActionSkills = [].concat.apply(
                [],
                optionsActionSkills
                    .filter((it) => it.skills.some((skill) => !skill.selected && !skill.actioned && (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(skill.skillCode)))))
                    .map((it, groupIndex) => {
                        return it.skills
                            .filter((skill) => !skill.selected && !skill.actioned)
                            .map((skill) => {
                                return {
                                    groupIndex,
                                    skillName: skill.skillName,
                                    skillCode: skill.skillCode,
                                    skillLabel: skill.skillLabel,
                                    skillLevel: skill.skillLevel,
                                    skillType: skill.skillType,
                                };
                            });
                    })
            );
        }

        return (
            <Modal isOpen={this.state.showActionPlanEdit} toggle={!this.state.actionPlanUpdating && this.togleActionPlanEditModal} size="lg">
                <ModalHeader toggle={!this.state.actionPlanUpdating && this.togleActionPlanEditModal}>
                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditTitle" contentText="Edit Action" />
                </ModalHeader>
                <ModalBody>
                    {this.state.actionPlanUpdating ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditStatus" contentText="Status" />
                                </Label>
                                <Col sm={9}>
                                    <Combobox
                                        onSelect={(value) =>
                                            this.handleChange({
                                                target: {
                                                    name: "status",
                                                    value: value.value,
                                                },
                                            })
                                        }
                                        data={optionsStatus}
                                        textField="label"
                                        dataKey="value"
                                        value={optionsStatus.find((it) => it.value == form.status.value)}
                                        defaultValue={optionsStatus.find((it) => it.value == form.status.value)}
                                        style={{ fontSize: "1rem", fontFamily: "IBMPlexSans" }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditType" contentText="Type" />
                                </Label>
                                <Col sm={9}>
                                    {editedOptionsActionTypes && (
                                        <Combobox
                                            onSelect={(value) =>
                                                this.handleChange({
                                                    target: {
                                                        name: "actionType",
                                                        value: value.name,
                                                    },
                                                })
                                            }
                                            value={editedOptionsActionTypes.find((it) => it.name == form.actionType.value)}
                                            data={editedOptionsActionTypes}
                                            textField="name"
                                            dataKey="name"
                                            defaultValue={editedOptionsActionTypes.find((it) => it.name == form.actionType.value)}
                                            style={{ fontSize: "1rem", fontFamily: "IBMPlexSans" }}
                                            readOnly={editedAction && editedAction.interventionId}
                                        />
                                    )}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditName" contentText="Name" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="text" name="name" id={"name"} value={form.name.value} invalid={form.name.touched && !form.name.valid} onChange={this.handleChange} readOnly={editedAction && editedAction.interventionId} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditTarget" contentText="Target" />
                                </Label>
                                <Col sm={9}>
                                    <DatePicker selected={form.targetCompletionOn.value ? new Date(form.targetCompletionOn.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleChangeDate("targetCompletionOn", value)} className="form-control" isClearable />
                                    {form && !form.targetCompletionOn.valid && <span style={{ color: "#ff0000", fontSize: "12px" }}>Target date is mandatory</span>}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditDescription" contentText="Description" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="textarea" name="description" id={"inputDescription"} value={form.description.value} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditWebsiteLink" contentText="Website" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="text" name="link" id={"inputLink"} value={form.link.value} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditDuration" contentText="Duration" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="text" name="duration" id={"inputDuration"} invalid={form.duration.touched && (!form.duration.valid || !form.duration.isValidFormat)} value={form.duration.value} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionEditGaps" contentText="Gaps" />
                                </Label>
                                <Col sm={9}>
                                    {selectedSkills
                                        .filter((it) => ![...jobLorProfiles.map((it) => it.lorCode + it.level), ...jobSkillProfiles.map((it) => it.skillCode + it.level)].includes(it.skillCode + it.skillLevel))
                                        .map((selectedSkill, index) => {
                                            let tooltipDescription = "";
                                            if (selectedSkill.skillType == "Skill") {
                                                const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == selectedSkill.skillName && it.skillsCode == selectedSkill.skillCode)));
                                                const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == selectedSkill.skillName && it.skillsCode == selectedSkill.skillCode));
                                                const skill = subCategory && subCategory.skills.find((it) => it.name == selectedSkill.skillName && it.skillsCode == selectedSkill.skillCode);
                                                const skillLevel = skill && skill.levels.find((it) => it.level == selectedSkill.skillLevel);

                                                let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                tooltipDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${selectedSkill.skillCode} ${selectedSkill.skillLevel} Description`, skillLevelDescription);
                                            } else {
                                                const lor = lors.find((it) => it.lorCode == selectedSkill.skillCode);
                                                const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == selectedSkill.skillLevel) : null;

                                                if (lorLevel) {
                                                    tooltipDescription = this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description);
                                                }
                                            }

                                            return (
                                                <React.Fragment key={index}>
                                                    <div id={`divChkSkill${selectedSkill.id}`} style={{ float: "left" }}>
                                                        <CustomInput
                                                            type="checkbox"
                                                            className="edit-action-260-checkbox"
                                                            name={`chkSkill${selectedSkill.id}`}
                                                            id={`chkSkill${selectedSkill.id}`}
                                                            checked={!selectedSkill.removed}
                                                            label={
                                                                <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                    {selectedSkill.skillCode}
                                                                    {selectedSkill.skillLevel}
                                                                    {" - "}
                                                                    {selectedSkill.skillType == "Skill"
                                                                        ? this.contentTranslator(this.props.contentTranslations, "Skills", selectedSkill.skillName, selectedSkill.skillName)
                                                                        : this.contentTranslator(this.props.contentTranslations, "Lors", selectedSkill.skillName, selectedSkill.skillName)}{" "}
                                                                    {selectedSkill.skillLevel}
                                                                </div>
                                                            }
                                                            onChange={() => this.handleSkillCheckedChange(selectedSkill.skillName, selectedSkill.skillCode, selectedSkill.skillLevel, selectedSkill.skillType)}
                                                            inline
                                                            disabled={editedAction && editedAction.interventionId}
                                                        />
                                                    </div>
                                                    <UncontrolledTooltip
                                                        target={`divChkSkill${selectedSkill.id}`}
                                                        placement="bottom"
                                                        dangerouslySetInnerHTML={{
                                                            __html: tooltipDescription,
                                                        }}
                                                    ></UncontrolledTooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    {selectedSkills.find((it) => ![...jobLorProfiles.map((it) => it.lorCode + it.level), ...jobSkillProfiles.map((it) => it.skillCode + it.level)].includes(it.skillCode + it.skillLevel)) && <div style={{ clear: "both" }}>&nbsp;</div>}
                                    {jobLorProfiles &&
                                        jobLorProfiles
                                            .filter((it) => (showHiddenSkills || (!showHiddenSkills && availableLorCodes.includes(it.lorCode))) && (!it.actioned || selectedSkills.some((skill) => skill.skillCode == it.lorCode && skill.skillLevel >= it.level)))
                                            .sort((a, b) => (a.lorCode + a.level > b.lorCode + b.level ? 1 : -1))
                                            .map((jobLorProfile, index) => {
                                                const lor = lors.find((it) => it.lorCode == jobLorProfile.lorCode);
                                                const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == jobLorProfile.level) : null;

                                                return (
                                                    <React.Fragment key={index}>
                                                        <div id={`divChkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`} style={{ float: "left" }}>
                                                            <CustomInput
                                                                key={index}
                                                                type="checkbox"
                                                                className="edit-action-260-checkbox"
                                                                name={`chkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`}
                                                                id={`chkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`}
                                                                checked={!!selectedSkills.find((it) => it.skillCode == jobLorProfile.lorCode && it.skillLevel == jobLorProfile.level && !it.removed)}
                                                                label={
                                                                    <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                        {jobLorProfile.lorCode}
                                                                        {jobLorProfile.level}
                                                                        {" - "}
                                                                        {this.contentTranslator(this.props.contentTranslations, "Lors", jobLorProfile.lor, jobLorProfile.lor)} {jobLorProfile.level}
                                                                    </div>
                                                                }
                                                                onChange={() => this.handleSkillCheckedChange(jobLorProfile.lor, jobLorProfile.lorCode, jobLorProfile.level, "Lor")}
                                                                inline
                                                                disabled={editedAction && editedAction.interventionId}
                                                            />
                                                        </div>
                                                        <UncontrolledTooltip target={`divChkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`} placement="bottom">
                                                            {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                        </UncontrolledTooltip>
                                                    </React.Fragment>
                                                );
                                            })}
                                    {jobLorProfiles && jobLorProfiles.find((it) => (showHiddenSkills || (!showHiddenSkills && availableLorCodes.includes(it.lorCode))) && (!it.actioned || selectedSkills.some((skill) => skill.skillCode == it.lorCode && skill.skillLevel >= it.level))) && (
                                        <div style={{ clear: "both" }}>&nbsp;</div>
                                    )}
                                    {jobSkillProfiles &&
                                        jobSkillProfiles
                                            .filter((it) => (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(it.skillCode))) && (!it.actioned || selectedSkills.some((skill) => skill.skillCode == it.skillCode && skill.skillLevel == it.level)))
                                            .sort((a, b) => (a.skillCode + a.level > b.skillCode + b.level ? 1 : -1))
                                            .map((jobSkillProfile, index) => {
                                                const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode)));
                                                const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode));
                                                const skill = subCategory && subCategory.skills.find((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode);
                                                const skillLevel = skill && skill.levels.find((it) => it.level == jobSkillProfile.level);

                                                let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${jobSkillProfile.skillCode} ${jobSkillProfile.level} Description`, skillLevelDescription);

                                                return (
                                                    <React.Fragment key={index}>
                                                        <div id={`divChkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`} style={{ float: "left" }}>
                                                            <CustomInput
                                                                key={index}
                                                                type="checkbox"
                                                                className="edit-action-260-checkbox"
                                                                name={`chkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                id={`chkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                checked={!!selectedSkills.find((it) => it.skillCode == jobSkillProfile.skillCode && it.skillLevel == jobSkillProfile.level && !it.removed)}
                                                                label={
                                                                    <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                        {jobSkillProfile.skillCode}
                                                                        {jobSkillProfile.level}
                                                                        {" - "}
                                                                        {this.contentTranslator(this.props.contentTranslations, "Skills", jobSkillProfile.skill, jobSkillProfile.skill)} {jobSkillProfile.level}
                                                                    </div>
                                                                }
                                                                onChange={() => this.handleSkillCheckedChange(jobSkillProfile.skill, jobSkillProfile.skillCode, jobSkillProfile.level, "Skill")}
                                                                inline
                                                                disabled={editedAction && editedAction.interventionId}
                                                            />
                                                        </div>
                                                        <UncontrolledTooltip
                                                            target={`divChkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                            placement="bottom"
                                                            dangerouslySetInnerHTML={{
                                                                __html: skillLevelDescription,
                                                            }}
                                                        ></UncontrolledTooltip>
                                                    </React.Fragment>
                                                );
                                            })}
                                </Col>
                            </FormGroup>
                            {!(editedAction && editedAction.interventionId) && (
                                <FormGroup row>
                                    <Label sm={3} style={{ textAlign: "right" }}>
                                        <ContentTranslator page="SfiaProfile" name="OutstandingActionEditOtherSkills" contentText="Other skills" />
                                    </Label>
                                    <Col sm={9}>
                                        <Row>
                                            {pickedOtherSkills && pickedOtherSkills.length > 0 && (
                                                <Col sm={12}>
                                                    {pickedOtherSkills.map((pickedSkill, index) => {
                                                        let tooltipDescription = "";

                                                        if (pickedSkill.skillType == "Skill") {
                                                            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == pickedSkill.skillName && it.skillsCode == pickedSkill.skillCode)));
                                                            const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == pickedSkill.skillName && it.skillsCode == pickedSkill.skillCode));
                                                            const skill = subCategory && subCategory.skills.find((it) => it.name == pickedSkill.skillName && it.skillsCode == pickedSkill.skillCode);
                                                            const skillLevel = skill && skill.levels.find((it) => it.level == pickedSkill.skillLevel);

                                                            let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                            tooltipDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${pickedSkill.skillCode} ${pickedSkill.skillLevel} Description`, skillLevelDescription);
                                                        } else {
                                                            const lor = lors.find((it) => it.lorCode == pickedSkill.skillCode);
                                                            const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == pickedSkill.skillLevel) : null;

                                                            if (lorLevel) {
                                                                tooltipDescription = this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description);
                                                            }
                                                        }

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div id={`divChkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`} style={{ float: "left" }}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className="edit-action-260-checkbox"
                                                                        name={`chkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`}
                                                                        id={`chkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`}
                                                                        checked={!!selectedOtherSkills.find((it) => it.skillCode == pickedSkill.skillCode && it.skillLevel == pickedSkill.skillLevel)}
                                                                        label={
                                                                            <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                                {pickedSkill.skillCode}
                                                                                {pickedSkill.skillLevel}
                                                                                {" - "}
                                                                                {pickedSkill.skillType == "Skill"
                                                                                    ? this.contentTranslator(this.props.contentTranslations, "Skills", pickedSkill.skillName, pickedSkill.skillName)
                                                                                    : this.contentTranslator(this.props.contentTranslations, "Lors", pickedSkill.skillName, pickedSkill.skillName)}{" "}
                                                                                {pickedSkill.skillLevel}
                                                                            </div>
                                                                        }
                                                                        onChange={() => this.handleOtherSkillCheckedChange(pickedSkill.skillName, pickedSkill.skillCode, pickedSkill.skillLevel, pickedSkill.skillType)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    target={`divChkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: tooltipDescription,
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    <div style={{ clear: "both", paddingTop: "15px" }}></div>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col sm={9}>
                                                <Combobox
                                                    data={spreadedActionSkills.sort((a, b) => a.groupIndex - b.groupIndex)}
                                                    onSelect={(value) =>
                                                        this.handleChangeOtherSkill({
                                                            target: {
                                                                value: value.skillCode + "-" + value.skillLevel,
                                                            },
                                                        })
                                                    }
                                                    onChange={(value) => {
                                                        value == "" &&
                                                            this.handleChangeOtherSkill({
                                                                target: {
                                                                    value: "",
                                                                },
                                                            });
                                                    }}
                                                    textField="skillLabel"
                                                    groupBy="skillName"
                                                    filter="contains"
                                                    placeholder={this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "OutstandingActionEditSelectSkillPlaceholder", "Select skill")}
                                                    autoSelectMatches={true}
                                                    value={spreadedActionSkills.find((value) => value.skillCode + "-" + value.skillLevel == this.state.selectedOtherSkill)}
                                                    style={{ fontSize: "14px", fontFamily: "IBMPlexSans", width: "100%" }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <ButtonGroup>
                                                    <Button onClick={() => this.handleAddOtherSkill()} className="btn btn-white" disabled={!(this.state.selectedOtherSkill && this.state.selectedOtherSkill != "")}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                            )}
                            <FormGroup row>
                                <Col sm={3}></Col>
                                <Col sm={9}>
                                    <Button color="danger" onClick={this.handleSubmitDelete}>
                                        <FontAwesomeIcon icon={faTrash} /> <ContentTranslator page="SfiaProfile" name="OutstandingActionEditButtonDelete" contentText="Delete" />
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleSubmitEdit} disabled={!(this.state.isFormValid && (selectedSkills.filter((it) => !it.id || (it.id && !it.removed)).length > 0 || selectedOtherSkills.length > 0)) || this.state.actionPlanUpdating}>
                        <ContentTranslator page="SfiaProfile" name="OutstandingActionEditButtonSave" contentText="Save" />
                    </Button>
                    <Button color="secondary" onClick={this.togleActionPlanEditModal} disabled={this.state.actionPlanUpdating}>
                        <ContentTranslator page="SfiaProfile" name="OutstandingActionEditButtonClose" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleSkillCheckedChange(skillName, skillCode, skillLevel, skillType) {
        let selectedSkills = this.state.selectedSkills;

        const selectedSkill = {
            skillName,
            skillCode,
            skillLevel,
            skillType,
        };

        if (selectedSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel)) {
            const unSelectedSkill = selectedSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel);

            if (unSelectedSkill.id) {
                if (unSelectedSkill.removed) {
                    unSelectedSkill.removed = false;
                } else {
                    unSelectedSkill.removed = true;
                }

                const index = selectedSkills.indexOf(unSelectedSkill);
                if (index !== -1) {
                    selectedSkills[index].removed = unSelectedSkill.removed;
                }

                this.setState({ selectedSkills });
            } else {
                const index = selectedSkills.indexOf(unSelectedSkill);
                if (index !== -1) {
                    selectedSkills.splice(index, 1);
                }

                this.setState({ selectedSkills });
            }
        } else {
            this.setState({ selectedSkills: [...selectedSkills, selectedSkill] });
        }
    }

    handleChangeOtherSkill = (event) => {
        this.setState({ selectedOtherSkill: event.target.value });
    };

    handleAddOtherSkill() {
        const selectedOtherSkill = this.state.selectedOtherSkill;
        let { optionsActionSkills, pickedOtherSkills, selectedOtherSkills } = this.state;

        if (selectedOtherSkill && selectedOtherSkill !== "") {
            let optionsActionSkill = optionsActionSkills.find((it) => it.skills.some((skill) => `${skill.skillCode}-${skill.skillLevel}` == selectedOtherSkill));
            if (optionsActionSkill) {
                let skill = optionsActionSkill.skills.find((skill) => `${skill.skillCode}-${skill.skillLevel}` == selectedOtherSkill);
                if (skill) {
                    skill.selected = true;

                    pickedOtherSkills.push({
                        skillName: skill.skillName,
                        skillCode: skill.skillCode,
                        skillLevel: skill.skillLevel,
                        skillType: skill.skillType,
                    });

                    selectedOtherSkills.push({
                        skillName: skill.skillName,
                        skillCode: skill.skillCode,
                        skillLevel: skill.skillLevel,
                        skillType: skill.skillType,
                    });
                }

                this.setState({ optionsActionSkills, selectedOtherSkill: "", pickedOtherSkills, selectedOtherSkills });
            }
        }
    }

    handleOtherSkillCheckedChange(skillName, skillCode, skillLevel, skillType) {
        let selectedOtherSkills = this.state.selectedOtherSkills;

        const selectedSkill = {
            skillName,
            skillCode,
            skillLevel,
            skillType,
        };

        if (selectedOtherSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel)) {
            const unSelectedSkill = selectedOtherSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel);
            const index = selectedOtherSkills.indexOf(unSelectedSkill);
            if (index !== -1) {
                selectedOtherSkills.splice(index, 1);
            }

            this.setState({ selectedOtherSkills });
        } else {
            this.setState({ selectedOtherSkills: [...selectedOtherSkills, selectedSkill] });
        }
    }

    handleSubmitEdit() {
        const { editedAction, selectedSkills, selectedOtherSkills } = this.state;

        const actionRequest = {
            personEmail: this.props.managedPeople && this.props.managedPeople.personB.email,
            actionType: this.state.form.actionType.value,
            name: this.state.form.name.value,
            description: this.state.form.description.value,
            status: this.state.form.status.value,
            targetCompletionOn: this.state.form.targetCompletionOn.value,
            duration: this.state.form.duration.value,
            link: this.state.form.link.value,
            skills: [...selectedSkills.filter((it) => !it.removed), ...selectedOtherSkills.filter((it) => !it.removed)].map((skill) => {
                return {
                    id: skill.id ? skill.id : null,
                    name: skill.skillName,
                    code: skill.skillCode,
                    level: skill.skillLevel,
                    type: skill.skillType,
                };
            }),
        };

        this.setState({ actionPlanUpdating: true });
        Promise.resolve(this.props.managedPeople ? this.props.onActionPlan.updateManagedPeopleAction(editedAction.id, actionRequest) : this.props.onActionPlan.updateAction(editedAction.id, actionRequest)).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    console.log("this.props.actionPlanUpdatingError", this.props.actionPlanUpdatingError);
                    //this.props.generateAlert("danger", this.props.actionPlanUpdatingError);
                } else {
                    this.setState({ showActionPlanEdit: false });
                    //this.props.handleActionPlanUpdated(this.props.updatedAction);
                    this.initData();
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    }

    handleSubmitDelete() {
        const { editedAction } = this.state;

        const deletedId = editedAction.id;

        this.setState({ actionPlanUpdating: true });
        this.props.onActionPlan.deleteAction(deletedId).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    console.log("this.props.actionPlanUpdatingError", this.props.actionPlanUpdatingError);
                    //this.props.generateAlert("danger", this.props.actionPlanUpdatingError);
                } else {
                    this.setState({ showActionPlanEdit: false });
                    //this.props.handleActionPlanDeleted(deletedId);
                    this.initData();
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    }

    render() {
        const { loading, actions } = this.state;

        return (
            <Container style={containerStyle}>
                <Row>
                    <Col className="mb-2">
                        <span style={titleStyle}>
                            <ContentTranslator page="SfiaProfile" name="OutstandingActionTitle" contentText="Outstanding Actions" />
                        </span>
                    </Col>
                </Row>
                {this.modalActionPlanDetail()}
                {this.modalEditActionPlan()}
                {loading ? (
                    <Row className="align-items-center mb-2">
                        <Col className="p-0">
                            <center>
                                <Spinner animation="border" role="status"></Spinner>
                            </center>
                        </Col>
                    </Row>
                ) : actions && actions.length > 0 ? (
                    <React.Fragment>
                        <div style={{ maxHeight: "150px", minHeight: "150px", overflowX: "hidden", overflowY: "auto" }}>
                            {actions
                                .filter((it) => !it.status.toLowerCase().includes("done") && !it.hidden)
                                .sort((a, b) => new Date(a.targetCompletionOn) - new Date(b.targetCompletionOn))
                                .map((action, index) => {
                                    const row = action;
                                    return (
                                        <Row key={index} className="align-items-center mb-2">
                                            <Col xs="auto pr-0">
                                                {action.targetCompletionOn && new Date() > new Date(action.targetCompletionOn) && <FontAwesomeIcon className="d-block mx-auto" icon={faCircle} style={{ fontSize: "20px", color: "#ff0000" }} />}
                                                {(!action.targetCompletionOn || (action.targetCompletionOn && new Date() <= new Date(action.targetCompletionOn))) && (
                                                    <FontAwesomeIcon className="d-block mx-auto" icon={action.status.toLowerCase().includes("todo") ? farCircle : action.status.toLowerCase().includes("inprogress") ? faAdjust : faCheck} style={{ fontSize: "20px", color: "#3164c9" }} />
                                                )}
                                            </Col>
                                            <Col className="p-0">
                                                <p className="ml-2 mb-0 color-dark">
                                                    <span id={`outstandingActionSpan${index}`}>
                                                        {row.targetCompletionOn
                                                            ? this.parameterizedString(
                                                                  this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "OutstandingActionWithDueDate", "You have an action due by {s1}"),
                                                                  new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "long",
                                                                      day: "numeric",
                                                                  }).format(new Date(row.targetCompletionOn))
                                                              )
                                                            : this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "OutstandingActionNoDueDate", "You have an action with no due date")}
                                                    </span>
                                                    <UncontrolledTooltip target={`outstandingActionSpan${index}`} placement="bottom">
                                                        {action.name}
                                                    </UncontrolledTooltip>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <FontAwesomeIcon id={`outstandingActionFA${index}`} icon={faInfoCircle} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => this.openActionDetail(row)} />
                                                    <UncontrolledTooltip target={`outstandingActionFA${index}`} placement="bottom">
                                                        {action.name}
                                                    </UncontrolledTooltip>
                                                </p>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            {this.renderEmptyAction(3 - actions.length)}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Row className="align-items-center mb-2">
                            <Col xs="auto pr-0">
                                <FontAwesomeIcon className="d-block mx-auto" icon={faExclamationCircle} style={{ fontSize: "20px", color: "#3164c9" }} />
                            </Col>
                            <Col className="p-0">
                                <p className="ml-2 mb-0 color-dark">
                                    <ContentTranslator page="SfiaProfile" name="OutstandingActionNoActionMessage" contentText="Get started on improving your skills by creating some actions" />
                                </p>
                            </Col>
                        </Row>
                        {this.renderEmptyAction(2)}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.actionPlan.loading,
        error: state.actionPlan.error,
        actions: state.actionPlan.actions,
        actionTypes: state.actionPlan.actionTypes,
        actionSkills: state.actionPlan.actionSkills,

        companyLoading: state.company.loading,

        jobMatchesLoading: state.job.jobMatchesLoading,
        jobLoading: state.job.loading,
        jobMatches: state.job.jobMatches,
        jobLorProfiles: state.job.jobLorProfiles,
        jobSkillProfiles: state.job.jobSkillProfiles,

        loadingProfile: state.skillProfile.loading,
        errorProfile: state.skillProfile.error,
        skillProfiles: state.skillProfile.skillProfiles,

        profileTypes: state.skillProfile.profileTypes,

        loadingLor: state.skillProfile.lorLoading,
        errorLor: state.skillProfile.lorError,
        lorProfiles: state.skillProfile.lorProfiles,

        categories: state.category.categories,
        categoryLoading: state.category.loading,
        categoryError: state.category.error,

        lors: state.lor.lors,

        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,

        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillStep);
