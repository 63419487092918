import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminAdminRoleAPI {
    static getAdminRoles() {
        return axios
            .get(`/adminRoles`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getAdminRolePageAssignments(adminRoleId) {
        return axios
            .get(`/adminRoles/${adminRoleId}/pageassignments`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createAdminRolePageAssignments(adminRole) {
        return axios
            .post(`/adminRoles/pageassignments`, adminRole)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateAdminRolePageAssignments(adminRoleId, adminRole) {
        return axios
            .post(`/adminRoles/${adminRoleId}/pageassignments`, adminRole)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static renameAdminRole(adminRoleId, name) {
        return axios
            .post(`/adminRoles/${adminRoleId}/rename`, { name })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteAdminRole(adminRoleId) {
        return axios
            .delete(`/adminRoles/${adminRoleId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminAdminRoleAPI;
