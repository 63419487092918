import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

class AdminRoleRename extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            adminRoleName: this.props.adminRoleName,
            adminRoleNameInvalid: false,
            adminRoleNameTouched: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.adminRoleName !== this.props.adminRoleName) {
            this.setState({ adminRoleName: this.props.adminRoleName, adminRoleNameTouched: false, adminRoleNameInvalid: false });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        if (showDialog) {
            this.setState({ adminRoleName: this.props.adminRoleName });
            this.props.reopenManageAdminRole();
        }
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleConfirm = () => {
        this.setState({
            showDialog: false,
        });
        this.props.handleConfirmRename(this.state.adminRoleName);
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ adminRoleNameTouched: true, adminRoleName: value, adminRoleNameInvalid: !value || value == "" });
    };

    render() {
        const { adminRoleName, adminRoleNameTouched, adminRoleNameInvalid } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}></ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12} style={{ textAlign: "center" }}>
                                            Enter name
                                        </Label>
                                        <Col sm={12}>
                                            <Input
                                                type="text"
                                                name="adminRoleName"
                                                id="adminRoleName"
                                                value={adminRoleName}
                                                invalid={adminRoleNameTouched && adminRoleNameInvalid}
                                                onChange={this.handleChange}
                                                // disabled={this.state.isUpdating || (adminRoles && adminRoles.length >= maxAdminRoles)}
                                                maxLength={30}
                                                placeholder="Enter Name (Max 30 chars)"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handleConfirm();
                                }}
                            >
                                Ok
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoleRename);
