import * as countryActions from "../../../store/actions/countryAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import ManageAdminRole from "./ManageAdminRole";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class UserPageAssignments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            selectedPerson: this.props.selectedPerson,
            selectedAdminRoleName: null,
            personPageAssignments: null,
            staticPersonPageAssignments: null,
            showDialogManageAdminRoleTs: null,
            viewAdminRoleMode: false,
        };
    }

    componentDidMount() {
        if (this.props.selectedPerson) {
            this.props.onPerson.getPersonPageAssignments(this.props.selectedPerson.id).then(() => {
                this.setState({ personPageAssignments: [...this.props.personPageAssignments], staticPersonPageAssignments: [...this.props.personPageAssignments] });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.selectedPerson !== this.props.selectedPerson) {
            this.setState({ selectedPerson: this.props.selectedPerson });
            if (this.props.selectedPerson) {
                this.props.onPerson.getPersonPageAssignments(this.props.selectedPerson.id).then(() => {
                    this.setState({ personPageAssignments: [...this.props.personPageAssignments], staticPersonPageAssignments: [...this.props.personPageAssignments] });
                });
            }
        }
    }

    togleDialogModal = (updatedAdminRoleName) => {
        const showDialog = this.state.showDialog;
        if (showDialog) {
            this.setState({ selectedAdminRoleName: null, viewAdminRoleMode: false, personPageAssignments: this.state.staticPersonPageAssignments ? [...this.state.staticPersonPageAssignments] : [] });
            this.props.reopenPersonDetail(updatedAdminRoleName);
        }
        this.setState({
            showDialog: !showDialog,
            editMode: false,
        });
    };

    handleClearAll = () => {
        let { personPageAssignments } = this.state;

        personPageAssignments.forEach((personPageAssignment) => {
            personPageAssignment.operations.forEach((operation) => {
                operation.allow = false;
            });
        });

        this.setState({ personPageAssignments: [...personPageAssignments] });
    };

    handleChangeCheckBox = (pageName, operation, allow) => {
        let { personPageAssignments } = this.state;

        let personPageAssignment = personPageAssignments.find((it) => it.pageName == pageName);
        if (personPageAssignment) {
            if (personPageAssignment.operations && personPageAssignment.operations.find((it) => it.operation == operation)) {
                let objOperation = personPageAssignment.operations.find((it) => it.operation == operation);
                objOperation.allow = allow;
            }
        }

        this.setState({ personPageAssignments: [...personPageAssignments] });
    };

    handlePerformSave = () => {
        const { selectedPerson, personPageAssignments, selectedAdminRoleName } = this.state;
        this.setState({ isUpdating: true });
        this.props.onPerson.updatePersonPageAssignments(selectedPerson.id, selectedAdminRoleName ? selectedAdminRoleName : selectedPerson.adminRoleName, personPageAssignments).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Update access permissions success");
                    this.setState({ personPageAssignments: [...this.props.personPageAssignments], staticPersonPageAssignments: [...this.props.personPageAssignments] });
                    this.togleDialogModal(selectedAdminRoleName ? selectedAdminRoleName : selectedPerson.adminRoleName);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleOpenLoadAdminRoles = () => {
        this.setState({ showDialogManageAdminRoleTs: new Date(), showDialog: false, manageAdminRoleMode: "Load Admin Roles" });
    };

    handleOpenSaveAdminRole = () => {
        this.setState({ showDialogManageAdminRoleTs: new Date(), showDialog: false, manageAdminRoleMode: "Save Admin Role", adminRolePageAssignments: this.state.personPageAssignments });
    };

    reopenPageAssignment = () => {
        this.setState({ showDialog: true, selectedAdminRoleName: null });
    };

    handleViewAdminRole = (adminRoleName, pageAssignments) => {
        this.setState({
            showDialog: true,
            selectedAdminRoleName: adminRoleName,
            personPageAssignments: [
                ...pageAssignments.map((it) => {
                    return {
                        pageName: it.pageName,
                        operations: it.operations.map((entry) => {
                            return {
                                operation: entry.operation,
                                allow: entry.allow,
                            };
                        }),
                    };
                }),
            ],
            viewAdminRoleMode: true,
        });
    };

    handleLoadAdminRole = (adminRoleName, pageAssignments) => {
        this.setState({
            showDialog: true,
            selectedAdminRoleName: adminRoleName,
            personPageAssignments: [
                ...pageAssignments.map((it) => {
                    return {
                        pageName: it.pageName,
                        operations: it.operations.map((entry) => {
                            return {
                                operation: entry.operation,
                                allow: entry.allow,
                            };
                        }),
                    };
                }),
            ],
            viewAdminRoleMode: false,
        });
    };

    render() {
        const { selectedPerson, personPageAssignments, selectedAdminRoleName, viewAdminRoleMode } = this.state;

        return (
            <React.Fragment>
                <ManageAdminRole
                    reopenPageAssignment={this.reopenPageAssignment}
                    handleViewAdminRole={this.handleViewAdminRole}
                    handleLoadAdminRole={this.handleLoadAdminRole}
                    showDialog={this.state.showDialogManageAdminRoleTs}
                    generateAlert={this.props.generateAlert}
                    mode={this.state.manageAdminRoleMode}
                    pageAssignments={this.state.adminRolePageAssignments ? this.state.adminRolePageAssignments : []}
                ></ManageAdminRole>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>
                        <div style={{ width: "140%" }}>
                            <Row>
                                <Col md={selectedPerson && selectedPerson.adminRoleName ? "6" : "12"}>{selectedPerson ? `Assign permissions for ${selectedPerson.firstName} ${selectedPerson.lastName}` : "Person Page Assignments"}</Col>
                                {(selectedAdminRoleName || (selectedPerson && selectedPerson.adminRoleName)) && <Col md="6" style={{ textAlign: "right" }}>{`Admin Role used: ${selectedAdminRoleName ? selectedAdminRoleName : selectedPerson.adminRoleName}`}</Col>}
                            </Row>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            {personPageAssignments &&
                                personPageAssignments.length > 0 &&
                                personPageAssignments
                                    .filter(
                                        (it) =>
                                            (it.pageName != "Manage Extra Frameworks" && it.pageName != "Manage SFIA Credentials and Reports" && it.pageName != "Manage SSO Setting" && it.pageName != "Manage LoR Category" && it.pageName != "Manage LoR") ||
                                            (this.props.containerConfig && this.props.containerConfig.showExtraFrameworkAdmin && it.pageName == "Manage Extra Frameworks" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.showSfiaCertificationAdmin && it.pageName == "Manage SFIA Credentials and Reports" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.allowManageSSOSetting && it.pageName == "Manage SSO Setting" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer && it.pageName == "Manage LoR Category" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == false && it.pageName == "Manage LoR" ? true : false)
                                    )
                                    .map((personPageAssignment) => (
                                        <React.Fragment key={personPageAssignment.pageName}>
                                            <Row>
                                                <Col md={3}>{personPageAssignment.pageName}</Col>
                                                <Col md={9}>
                                                    <Row>
                                                        {personPageAssignment.operations &&
                                                            personPageAssignment.operations.length &&
                                                            personPageAssignment.operations.map((operation) => (
                                                                <Col md={3} key={`colchk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`}>
                                                                    <Row>
                                                                        <Col md={2} style={{ paddingRight: "0px" }}>
                                                                            <Input
                                                                                type="checkbox"
                                                                                name={`chk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`}
                                                                                id={`chk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`}
                                                                                checked={operation.allow}
                                                                                disabled={this.state.isUpdating || viewAdminRoleMode}
                                                                                onChange={() => this.handleChangeCheckBox(personPageAssignment.pageName, operation.operation, !operation.allow)}
                                                                            />
                                                                        </Col>
                                                                        <Col md={10} style={{ paddingLeft: "0px" }}>
                                                                            <Label for={`chk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`} style={{ fontSize: "12px" }} check>
                                                                                {operation.operation}
                                                                            </Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            ))}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {viewAdminRoleMode ? (
                            <React.Fragment>
                                <Button
                                    color="success"
                                    onClick={() => {
                                        this.setState({ viewAdminRoleMode: false });
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Load Admin Role
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.togleDialogModal();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Close
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleOpenLoadAdminRoles();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Load Admin Role
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleOpenSaveAdminRole();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Save as Admin Role
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleClearAll();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Clear All
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handlePerformSave();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Save
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.togleDialogModal();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Cancel
                                </Button>
                            </React.Fragment>
                        )}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personsError: state.adminPerson.error,
        loading: state.adminPerson.loading,
        isUpdating: state.adminPerson.isUpdating,
        countries: state.country.countries,
        resetPasswordSent: state.adminPerson.resetPasswordSent,
        personPageAssignments: state.adminPerson.personPageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onCountry: bindActionCreators(countryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPageAssignments);
