import * as authActions from "../../../store/actions/authAction";
import * as configActions from "../../../store/actions/configAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import ColumnDisplaySetting from "../ColumnDisplaySetting";
import GenerateUserProfileReports from "./GenerateUserProfileReports";
import UserAddNew from "./UserAddNew";
import UserBulkUpload from "./UserBulkUpload";
import UserBulkUploadProfiles from "./UserBulkUploadProfiles";
import UserDetail from "./UserDetail";
import UserRoleCell from "./UserRoleCell";
import UserUpdatePersonMiscellaneousConfig from "./UserUpdatePersonMiscellaneousConfig";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faCog, faEnvelope, faFileExcel, faInfoCircle, faMinusCircle, faPlus, faPrint, faSquareCheck, faSquareMinus, faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            persons: [],
            selectedPerson: null,
            showDialogTs: null,
            showDialogNewUserTs: null,
            showDialogUserDetailTs: null,
            showDialogUserReportTs: null,
            showDialogUpdatePersonMiscellaneousConfigTs: null,
            isUpdating: false,
            showDeletePersonFirstConfirmationDialog: false,
            showDeletePersonSecondConfirmationDialog: false,
            deletedPerson: null,
            assignMode: false,
            inviteMode: false,
            unassignMode: false,
            exportProfileMode: false,
            personsSelected: [],
            showAssignInviteConfirmationDialog: false,
            showContinueToInviteDialog: false,
            checkShowInvited: false,
            showColumnSettings: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.initData();
            this.setState({ loading: this.props.loading });
        });
    }

    searchProperties = () => {
        const pageName = this.props.mode == "ManageUser" ? "Manage Users" : this.props.mode == "AssignSurvey" ? "Assign Participants" : "Track Participant Progress";
        return this.props.columnDisplaySettings ? this.props.columnDisplaySettings.filter((it) => it.pageName == pageName && it.visible == true && it.searchable == true).map((it) => it.columnName) : [];
    };

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons && this.props.persons) {
            this.setState({ persons: this.reformPersons(this.props.persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))) });
        }

        if (prevProps.mode !== this.props.mode && prevProps.mode) {
            this.handleChangeMode();
        }
    }

    reformPersons = (persons) => {
        return persons.map((it) => {
            return {
                id: it.id,
                companyId: it.companyId,
                firstName: it.firstName,
                lastName: it.lastName,
                email: it.email,
                salutation: it.salutation ? it.salutation : "",
                position: it.position ? it.position : "",
                inactive: it.inactive,
                active: it.inactive ? "Inactive" : "Active",
                personType: it.personType ? it.personType : "",
                accessGroup: it.accessGroup,
                allowNotifications: it.allowNotifications,
                lastActivityOn: it.lastActivityOn,
                lastActivitySystem: it.lastActivitySystem,
                createdOn: it.createdOn,
                countryCode: it.countryCode ? it.countryCode : "",
                country: it.country ? it.country : "",
                location: it.location ? it.location : "",
                selfAssessmentReason: it.selfAssessmentReason ? it.selfAssessmentReason : "",
                encryptedId: it.encryptedId,
                team: it.team ? it.team : "",
                usePreferredName: it.usePreferredName,
                other: it.other ? it.other : "",
                department: it.department ? it.department : "",
                businessUnit: it.businessUnit ? it.businessUnit : "",
                division: it.division ? it.division : "",
                territory: it.territory ? it.territory : "",
                costCentre: it.costCentre ? it.costCentre : "",
                supervisor: it.supervisor ? it.supervisor : "",
                validatedDate: it.validatedDate,
                isCandidate: it.isCandidate,
                strCandidate: it.isCandidate ? "TRUE" : "",
                isContractor: it.isContractor,
                strContractor: it.isContractor ? "TRUE" : "",
                isMentorIneligible: it.isMentorIneligible,
                strMentorIneligible: it.isMentorIneligible ? "TRUE" : "",
                isAnalyticsUser: it.isAnalyticsUser,
                leaveOn: it.leaveOn,
                isAssigned: it.isAssigned,
                strAssigned: it.isAssigned ? "TRUE" : "FALSE",
                isInvited: it.isInvited,
                strInvited: it.isInvited ? "TRUE" : "FALSE",
                invitedOn: it.invitedOn,
                accounts: it.accounts ? [...it.accounts] : null,
                teams: it.teams ? [...it.teams] : null,
                profilePictureUrl: it.profilePictureUrl,
                activityStatementInterval: it.activityStatementInterval,
                lastUpdatedOn: it.lastUpdatedOn,
                lastUpdatedVersion: it.lastUpdatedVersion,
                latestAssessmentId: it.latestAssessmentId,
                cartridgeId: it.cartridgeId,
                hasOutstandingAction: it.hasOutstandingAction,
                roles: it.roles ? [...it.roles] : null,
                strRoles:
                    it.roles && it.roles.length > 0
                        ? it.roles
                              .sort((a, b) => ((a.role == "Admin" ? 2 : 1) > (b.role == "Admin" ? 2 : 1) ? 1 : -1))
                              .map((it) => {
                                  return it.role;
                              })
                              .join(", ")
                        : "User",
                groups: it.groups ? [...it.groups] : null,
                totalQuestions: it.totalQuestions,
                answeredQuestions: it.answeredQuestions,
                totalTimeTaken: it.totalTimeTaken,
                percentage: it.percentage,
                percentageHundred: it.percentage ? it.percentage * 100 : 0,
                progress: it.totalQuestions ? `${it.answeredQuestions} / ${it.totalQuestions}` : "0 / 0",
                hasEndorseProfile: it.hasEndorseProfile,
                hasAction: it.hasAction,
                latestInvitationEmailReminderNumber: it.latestInvitationEmailReminderNumber,
                latestInvitationEmailReminderSentOn: it.latestInvitationEmailReminderSentOn,
                resetPasswordRequested: it.resetPasswordRequested,
                upRelationships: it.upRelationships,
                downRelationships: it.downRelationships,
                havePassword: it.havePassword,
                invitationEmailMessageId: it.invitationEmailMessageId,
                invitationEmailMessageStatus: it.invitationEmailMessageStatus,
                invitationEmailMessageReason: it.invitationEmailMessageReason,
                messageSent:
                    it.invitationEmailMessageId && it.isInvited
                        ? it.invitationEmailMessageStatus == "delivered"
                            ? "TRUE"
                            : it.invitationEmailMessageStatus == "drop" || it.invitationEmailMessageStatus == "dropped" || it.invitationEmailMessageStatus == "bounce" || it.invitationEmailMessageStatus == "deferred"
                            ? "FALSE"
                            : ""
                        : "",
                invitedSentOn:
                    it.invitationEmailMessageId && it.isInvited
                        ? it.invitationEmailMessageStatus == "delivered"
                            ? it.invitedOn
                            : it.invitationEmailMessageStatus == "drop" || it.invitationEmailMessageStatus == "dropped" || it.invitationEmailMessageStatus == "bounce" || it.invitationEmailMessageStatus == "deferred"
                            ? null
                            : null
                        : null,
                surveyStarted: it.accounts && it.accounts.length > 0 ? (it.accounts.find((it) => it.roleId) ? "TRUE" : "FALSE") : "FALSE",
                endorsedProfileCreated: it.endorsedProfileCreated,
                endorsed: it.endorsedProfileCreated ? "TRUE" : "FALSE",
                miscellaneous1: it.miscellaneous1,
                miscellaneous2: it.miscellaneous2,
                miscellaneous3: it.miscellaneous3,
                miscellaneous4: it.miscellaneous4,
                miscellaneous5: it.miscellaneous5,
                selfAssessedLastProfileVersion: it.selfAssessedLastProfileVersion,
                selfAssessedLastProfileUpdatedOn: it.selfAssessedLastProfileUpdatedOn,
                selfAssessedLastProfileApproved: it.selfAssessedLastProfileVersion ? (it.selfAssessedLastProfileApproved ? "TRUE" : "FALSE") : "",
                endorsementLastUpdatedVersion: it.endorsementLastUpdatedVersion,
                endorsementLastUpdated: it.endorsementLastUpdated,
                endorsementLatestStatus: it.endorsementLatestStatus,
                skipSelfAssessmentSurvey: it.skipSelfAssessmentSurvey,
                strSkipSelfAssessmentSurvey: it.skipSelfAssessmentSurvey ? "TRUE" : "",
                roleBasedSurveyId: it.roleBasedSurveyId,
                roleBasedSurvey: it.roleBasedSurvey,
                fte: it.fte,
                adminRoleName: it.adminRoleName,
            };
        });
    };

    initData = () => {
        this.props.onConfig.getAdminConfig().then(() => {});

        this.props.onPerson.getPersons().then(() => {
            this.setState({
                persons: this.reformPersons(
                    this.props.mode == "TrackProgress"
                        ? this.props.persons.filter((it) => !it.inactive && it.isAssigned && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                        : this.props.mode == "AssignSurvey"
                        ? this.props.persons.filter((it) => !it.inactive && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                        : this.props.persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                ),
            });
        });
    };

    handleRefresh = () => {
        this.setState({ assignMode: false, inviteMode: false, unassignMode: false, exportProfileMode: false, personsSelected: [] });
        this.initData();
    };

    handleChangeMode = () => {
        this.setState({ assignMode: false, inviteMode: false, unassignMode: false, exportProfileMode: false, personsSelected: [] });

        this.setState({
            persons: this.reformPersons(
                this.props.mode == "TrackProgress"
                    ? this.props.persons.filter((it) => !it.inactive && it.isAssigned && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                    : this.props.mode == "AssignSurvey"
                    ? this.props.persons.filter((it) => !it.inactive && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                    : this.props.persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
            ),
        });
    };

    openPersonDetail = (person) => {
        this.setState({ showDialogUserDetailTs: new Date(), selectedPerson: person });
    };

    openUpdatePersonMiscellaneousConfigDialog = () => {
        this.setState({ showDialogUpdatePersonMiscellaneousConfigTs: new Date() });
    };

    openGeneratePersonReport = (person) => {
        this.setState({ showDialogUserReportTs: new Date(), selectedPerson: person });
    };

    personRolesUpdated = (personId, roles) => {
        const persons = this.state.persons;

        this.setState({ persons: [...persons] });
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportIndividuals = () => {
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        if (this.props.mode == "ManageUser") {
            this.props.onPerson.exportPersonsDataExcel(timezoneOffset).then(() => {
                if (!this.props.loadingPrint && !this.props.personsError) {
                    if (this.props.printData) {
                        var today = new Date();
                        var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                        FileDownload(this.props.printData, `ExportIndividuals_${strToday}.xlsx`);
                    }
                }
            });
        } else if (this.props.mode == "TrackProgress") {
            this.props.onPerson.exportPersonsAssessmentProgressDataExcel(timezoneOffset).then(() => {
                if (!this.props.loadingPrint && !this.props.personsError) {
                    if (this.props.printData) {
                        var today = new Date();
                        var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                        FileDownload(this.props.printData, `PaticipantAssessmentProgress_${strToday}.xlsx`);
                    }
                }
            });
        }
    };

    handleImportIndividuals = () => {
        this.setState({ showDialogTs: new Date() });
    };

    handleAddNew = () => {
        this.setState({ showDialogNewUserTs: new Date() });
    };

    handleDeletePerson = (person) => {
        this.setState({ deletedPerson: person, showDeletePersonFirstConfirmationDialog: true });
    };

    handleConfirmDeletePersonFirstConfirmation = () => {
        this.setState({ showDeletePersonFirstConfirmationDialog: false, showDeletePersonSecondConfirmationDialog: true });
    };

    togleDeletePersonFirstConfirmationModal = () => {
        const showDeletePersonFirstConfirmationDialog = this.state.showDeletePersonFirstConfirmationDialog;
        this.setState({ showDeletePersonFirstConfirmationDialog: !showDeletePersonFirstConfirmationDialog });
    };

    renderDeletePersonFirstConfirmation = () => {
        return (
            <Modal isOpen={this.state.showDeletePersonFirstConfirmationDialog} toggle={this.togleDeletePersonFirstConfirmationModal}>
                <ModalHeader toggle={this.togleDeletePersonFirstConfirmationModal}>Delete Person Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>You are about to PERMANENTLY delete this person, including ALL associated skills and actions. Are you SURE you wish to continue?</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmDeletePersonFirstConfirmation();
                        }}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.togleDeletePersonFirstConfirmationModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmDeletePersonSecondConfirmation = () => {
        const person = this.state.deletedPerson;
        if (person) {
            this.setState({ isUpdating: true });

            this.props.onPerson.deletePerson(person.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.personsError) {
                        this.generateAlert("success", "Delete person success.");
                        this.handleRefresh();
                        this.setState({ showDeletePersonSecondConfirmationDialog: false });
                    } else {
                        this.generateAlert("danger", this.props.personsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    togleDeletePersonSecondConfirmationModal = () => {
        const showDeletePersonSecondConfirmationDialog = this.state.showDeletePersonSecondConfirmationDialog;
        this.setState({ showDeletePersonSecondConfirmationDialog: !showDeletePersonSecondConfirmationDialog });
    };

    renderDeletePersonSecondConfirmation = () => {
        return (
            <Modal isOpen={this.state.showDeletePersonSecondConfirmationDialog} toggle={!this.state.isUpdating && this.togleDeletePersonSecondConfirmationModal}>
                <ModalHeader toggle={!this.state.isUpdating && this.togleDeletePersonSecondConfirmationModal}>Delete Person Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>By clicking OK you will be unable to retrieve ANY information relating to the person. Click Cancel if you are not sure.</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmDeletePersonSecondConfirmation();
                        }}
                        disabled={this.state.isUpdating}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.togleDeletePersonSecondConfirmationModal} disabled={this.state.isUpdating}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    toFixedIfNecessary = (value, dp) => {
        return +parseFloat(value).toFixed(dp);
    };

    handleFilterAssign = () => {
        const persons = this.reformPersons(this.props.persons);

        this.setState({
            assignMode: true,
            unassignMode: false,
            inviteMode: false,
            persons: [...persons.filter((it) => !it.isAssigned && !it.endorsedProfileCreated && !it.inactive && (!it.roles || (it.roles && it.roles.length == 0) || (it.roles && it.roles.some((it) => it.role == "User"))))],
        });
    };

    handleFilterUnAssign = () => {
        const persons = this.reformPersons(this.props.persons);

        this.setState({
            unassignMode: true,
            assignMode: false,
            inviteMode: false,
            persons: [...persons.filter((it) => it.isAssigned && !it.inactive && it.accounts && !it.accounts.some((it) => it.roleId) && (!it.roles || (it.roles && it.roles.length == 0) || (it.roles && it.roles.some((it) => it.role == "User"))))],
        });
    };

    handleFilterInvite = () => {
        const persons = this.reformPersons(this.props.persons);
        const checkShowInvited = this.state.checkShowInvited;

        this.setState({
            assignMode: false,
            unassignMode: false,
            inviteMode: true,
            persons: [...persons.filter((it) => (!it.isInvited || checkShowInvited) && it.isAssigned && !it.inactive && (!it.roles || (it.roles && it.roles.length == 0) || (it.roles && it.roles.some((it) => it.role == "User"))))],
            personsSelected: [],
        });
    };

    handleCancelAssignInvite = () => {
        const persons = this.reformPersons(this.props.persons);

        this.setState({
            assignMode: false,
            unassignMode: false,
            inviteMode: false,
            persons: [...persons.filter((it) => !it.inactive && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))],
            personsSelected: [],
        });
    };

    handleProcessAssignInvite = () => {
        this.setState({ showAssignInviteConfirmationDialog: true });
    };

    handleRowClick = (row) => {
        const persons = this.state.persons;
        const updatedData = persons.find((it) => it.id == row.id);
        updatedData.toggleSelected = !updatedData.toggleSelected;

        this.setState({ persons: [...persons], personsSelected: persons.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleSelectAll = () => {
        const { persons, searchKeyword, columnSearchKeywords } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(persons, searchKeyword, this.searchProperties(), columnSearchKeywords);

        if (filtered.filter((it) => it.toggleSelected).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSelected).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        persons.forEach((it) => {
            it.toggleSelected = false;
        });

        if (selectAll) {
            persons.forEach((it) => {
                if (filtered.find((f) => f.id == it.id)) {
                    it.toggleSelected = true;
                }
            });
        }

        this.setState({ persons: [...persons], personsSelected: persons.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const persons = this.state.persons;

        persons.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ persons: [...persons] });
    };

    togleAssignInviteConfirmationDialog = () => {
        const showAssignInviteConfirmationDialog = this.state.showAssignInviteConfirmationDialog;
        this.setState({ showAssignInviteConfirmationDialog: !showAssignInviteConfirmationDialog });
    };

    handleConfirmAssignInvite = () => {
        const { assignMode, inviteMode, unassignMode, personsSelected } = this.state;
        this.setState({ isUpdating: true });

        Promise.all([assignMode ? this.props.onPerson.assignPersons(personsSelected) : unassignMode ? this.props.onPerson.unassignPersons(personsSelected) : this.props.onPerson.invitePersons(personsSelected)]).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.personsError) {
                    this.setState({ showAssignInviteConfirmationDialog: false });
                    if (assignMode) {
                        this.setState({ showContinueToInviteDialog: true });
                    } else {
                        if (inviteMode) {
                            this.generateAlert("success", `Invitations sent successfully.`);
                        } else {
                            this.generateAlert("success", `${inviteMode ? "Invite" : "Un-Assign"} persons success.`);
                        }

                        this.setState({
                            persons: [...this.reformPersons(this.props.persons)],
                            assignMode: false,
                            inviteMode: false,
                            unassignMode: false,
                            personsSelected: [],
                        });
                    }

                    if (this.props.mode == "AssignSurvey" && (assignMode || unassignMode)) {
                        this.props.onConfig.getAdminConfig().then(() => {});
                    }
                } else {
                    this.generateAlert("danger", this.props.personsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    renderAssignInviteConfirmationDialog = () => {
        const { assignMode, unassignMode, inviteMode } = this.state;

        return (
            <Modal isOpen={this.state.showAssignInviteConfirmationDialog} toggle={!this.state.isUpdating ? this.togleAssignInviteConfirmationDialog : null}>
                <ModalHeader toggle={!this.state.isUpdating ? this.togleAssignInviteConfirmationDialog : null}>{`${assignMode ? "Assign" : unassignMode ? "Un-Assign" : "Invite"} Participants Confirmation`}</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>{`Are you sure want to process ${assignMode ? "assign" : unassignMode ? "Un-Assign" : "Invite"} participants?`}</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmAssignInvite();
                        }}
                        disabled={this.state.isUpdating}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.togleAssignInviteConfirmationDialog} disabled={this.state.isUpdating}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    togleContinueToInviteDialog = () => {
        const showContinueToInviteDialog = this.state.showContinueToInviteDialog;
        this.setState({ showContinueToInviteDialog: !showContinueToInviteDialog });
    };

    closeContinueToInviteDialog = () => {
        this.setState({
            persons: this.reformPersons(this.props.persons),
            assignMode: false,
            inviteMode: false,
            unassignMode: false,
            personsSelected: [],
            showContinueToInviteDialog: false,
        });
    };

    handleContinueToInvite = () => {
        const personsSelected = this.state.personsSelected;
        const persons = this.reformPersons(this.props.persons).filter((it) => !it.isInvited && it.isAssigned && !it.inactive && (!it.roles || (it.roles && it.roles.length == 0) || (it.roles && it.roles.some((it) => it.role == "User"))));

        if (personsSelected) {
            personsSelected.forEach((it) => {
                const personSelected = persons.find((p) => p.id == it);
                if (personSelected) {
                    personSelected.toggleSelected = true;
                }
            });
        }

        this.setState({
            persons: [...persons],
            assignMode: false,
            inviteMode: true,
            unassignMode: false,
            showContinueToInviteDialog: false,
        });
    };

    renderContinueToInviteDialog = () => {
        return (
            <Modal isOpen={this.state.showContinueToInviteDialog}>
                <ModalHeader>Continue to invite participants</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>Would you like to send the invites to these participants immediately?</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleContinueToInvite();
                        }}
                    >
                        Yes
                    </Button>
                    <Button color="secondary" onClick={this.closeContinueToInviteDialog}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleShowAllInviteChanged = () => {
        const checkShowInvited = this.state.checkShowInvited;
        this.setState({ checkShowInvited: !checkShowInvited }, () => {
            this.handleFilterInvite();
        });
    };

    showMessageReason = (reason) => {
        alert(reason);
    };

    handleShowColumnSettings = () => {
        const showColumnSettings = this.state.showColumnSettings;

        this.setState({ showColumnSettings: !showColumnSettings });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    handleExportProfiles = () => {
        this.setState({
            exportProfileMode: true,
            personsSelected: [],
            persons: this.reformPersons(this.props.persons.filter((it) => it.percentage == 1 && !it.inactive && it.isAssigned && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))),
        });
    };

    handleProcessExportProfiles = () => {
        const personsSelected = this.state.personsSelected;

        this.setState({ isUpdating: true });

        this.props.onPerson.exportPersonsProfiles(personsSelected).then(() => {
            if (!this.props.loadingPrint && !this.props.personsError) {
                if (this.props.printData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.printData, `PersonProfiles_${strToday}.json`);
                }

                this.setState({
                    exportProfileMode: false,
                    personsSelected: [],
                    persons: this.reformPersons(this.props.persons.filter((it) => !it.inactive && it.isAssigned && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))),
                });
            }

            this.setState({ isUpdating: this.props.loadingPrint });
        });
    };

    handleCancelExportProfiles = () => {
        this.setState({
            exportProfileMode: false,
            personsSelected: [],
            persons: this.reformPersons(this.props.persons.filter((it) => !it.inactive && it.isAssigned && it.roles.some((r) => r.role == "User")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))),
        });
    };

    handleImportProfiles = () => {
        this.setState({ showDialogImportProfileTs: new Date() });
    };

    handleUpdatePersonAdminRoleName = (personId, updatedAdminRoleName) => {
        const { persons } = this.state;
        const person = persons.find((it) => it.id == personId);
        if (person) {
            person.adminRoleName = updatedAdminRoleName;

            this.setState({ persons: [...persons], selectedPerson: person });
        }
    };

    render() {
        const { persons, searchKeyword, columnSearchKeywords } = this.state;
        const { config } = this.props;

        const pageAssignments = this.props.pageAssignments;
        let manageUserPageAssignment = null;
        let assignUserPageAssignment = null;
        let trackProgressPageAssignment = null;

        if (pageAssignments.find((it) => it.pageName == "Manage Users")) {
            manageUserPageAssignment = pageAssignments.find((it) => it.pageName == "Manage Users");
        }

        if (pageAssignments.find((it) => it.pageName == "Assign Participants")) {
            assignUserPageAssignment = pageAssignments.find((it) => it.pageName == "Assign Participants");
        }

        if (pageAssignments.find((it) => it.pageName == "Track Participant Progress")) {
            trackProgressPageAssignment = pageAssignments.find((it) => it.pageName == "Track Participant Progress");
        }

        let allowSetAdminRole = false;
        if (manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Set Admin Role" && it.allow == true)) {
            allowSetAdminRole = true;
        }

        const mode = this.props.mode;
        const openPersonDetail = this.openPersonDetail;
        const openGeneratePersonReport = this.openGeneratePersonReport;
        const personRolesUpdated = this.personRolesUpdated;
        const handleDeletePerson = this.handleDeletePerson;
        const toFixedIfNecessary = this.toFixedIfNecessary;
        const generateAlert = this.generateAlert;
        const showMessageReason = this.showMessageReason;
        const onTableFilterChange = this.onTableFilterChange;

        const handleRowClick = this.handleRowClick;
        const handleSelectAll = this.handleSelectAll;

        const selectableRows = (mode == "AssignSurvey" && (this.state.assignMode || this.state.unassignMode || this.state.inviteMode)) || this.state.exportProfileMode ? true : false;
        const filtered = this.filterArray(persons, searchKeyword, this.searchProperties(), columnSearchKeywords);

        const surveyStartedSort = (rowA, rowB) => {
            let startedA = "FALSE";
            let startedB = "FALSE";

            if (rowA.accounts && rowA.accounts.length > 0) {
                const account = rowA.accounts.sort((b, a) => new Date(a.createdOn) - new Date(b.createdOn))[0];
                if (account.roleId) {
                    startedA = "TRUE";
                }
            }

            if (rowB.accounts && rowB.accounts.length > 0) {
                const account = rowB.accounts.sort((b, a) => new Date(a.createdOn) - new Date(b.createdOn))[0];
                if (account.roleId) {
                    startedB = "TRUE";
                }
            }

            if (startedA > startedB) {
                return 1;
            }

            if (startedB > startedA) {
                return -1;
            }

            return 0;
        };

        const invitedDateSort = (rowA, rowB) => {
            if (new Date(rowA.invitedOn) > new Date(rowB.invitedOn)) {
                return 1;
            }

            if (new Date(rowB.invitedOn) > new Date(rowA.invitedOn)) {
                return -1;
            }

            return 0;
        };

        const invitedSentDateSort = (rowA, rowB) => {
            if (new Date(rowA.invitedSentOn) > new Date(rowB.invitedSentOn)) {
                return 1;
            }

            if (new Date(rowB.invitedSentOn) > new Date(rowA.invitedSentOn)) {
                return -1;
            }

            return 0;
        };

        const selfAssessedLastProfileUpdatedOnSort = (rowA, rowB) => {
            if (new Date(rowA.selfAssessedLastProfileUpdatedOn) > new Date(rowB.selfAssessedLastProfileUpdatedOn)) {
                return 1;
            }

            if (new Date(rowB.selfAssessedLastProfileUpdatedOn) > new Date(rowA.selfAssessedLastProfileUpdatedOn)) {
                return -1;
            }

            return 0;
        };

        const endorsementLastUpdatedSort = (rowA, rowB) => {
            if (new Date(rowA.endorsementLastUpdated) > new Date(rowB.endorsementLastUpdated)) {
                return 1;
            }

            if (new Date(rowB.endorsementLastUpdated) > new Date(rowA.endorsementLastUpdated)) {
                return -1;
            }

            return 0;
        };

        const leaveOnSort = (rowA, rowB) => {
            if (new Date(rowA.leaveOn) > new Date(rowB.leaveOn)) {
                return 1;
            }

            if (new Date(rowB.leaveOn) > new Date(rowA.leaveOn)) {
                return -1;
            }

            return 0;
        };

        const candidateSort = (rowA, rowB) => {
            let candidateA = rowA.isCandidate ? "TRUE" : "FALSE";
            let candidateB = rowB.isCandidate ? "TRUE" : "FALSE";

            if (candidateA > candidateB) {
                return 1;
            }

            if (candidateB > candidateA) {
                return -1;
            }

            return 0;
        };

        const contractorSort = (rowA, rowB) => {
            let contractorA = rowA.isContractor ? "TRUE" : "FALSE";
            let contractorB = rowB.isContractor ? "TRUE" : "FALSE";

            if (contractorA > contractorB) {
                return 1;
            }

            if (contractorB > contractorA) {
                return -1;
            }

            return 0;
        };

        const mentorIneligibleSort = (rowA, rowB) => {
            let mentorIneligibleA = rowA.isMentorIneligible ? "TRUE" : "FALSE";
            let mentorIneligibleB = rowB.isMentorIneligible ? "TRUE" : "FALSE";

            if (mentorIneligibleA > mentorIneligibleB) {
                return 1;
            }

            if (mentorIneligibleB > mentorIneligibleA) {
                return -1;
            }

            return 0;
        };

        const endorsedProfileCreatedSort = (rowA, rowB) => {
            let startedA = "FALSE";
            let startedB = "FALSE";

            if (rowA.endorsedProfileCreated) {
                startedA = "TRUE";
            }

            if (rowB.endorsedProfileCreated) {
                startedB = "TRUE";
            }

            if (startedA > startedB) {
                return 1;
            }

            if (startedB > startedA) {
                return -1;
            }

            return 0;
        };

        const endorsementLatestStatusSort = (rowA, rowB) => {
            let statusA = rowA.endorsementLatestStatus ? rowA.endorsementLatestStatus : rowA.endorsementLastUpdatedVersion ? "1" : "";
            let statusB = rowB.endorsementLatestStatus ? rowB.endorsementLatestStatus : rowB.endorsementLastUpdatedVersion ? "1" : "";

            if (statusA > statusB) {
                return 1;
            }

            if (statusB > statusA) {
                return -1;
            }

            return 0;
        };

        const pageName = this.props.mode == "ManageUser" ? "Manage Users" : this.props.mode == "AssignSurvey" ? "Assign Participants" : "Track Participant Progress";
        const columnDisplaySettings = this.props.columnDisplaySettings ? this.props.columnDisplaySettings.filter((it) => it.pageName == pageName) : [];

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                            id={`headerChkSelectAll`}
                            icon={filtered.filter((it) => it.toggleSelected).length == 0 ? faSquare : filtered.filter((it) => !it.toggleSelected).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSelected).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => handleSelectAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "80px",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Input type="checkbox" id={`chkRow${row.id}`} checked={row.toggleSelected} onClick={(e) => handleRowClick(row)} />
                        </div>
                    );
                },
                omit: !selectableRows,
            },
            {
                id: "lastUpdatedOn",
                name: "Action",
                selector: (row) => row.lastUpdatedOn,
                sortable: false,
                width: "100px",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <FontAwesomeIcon id={`iconUserDetail${row.id}`} icon={faInfoCircle} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openPersonDetail(row)} />
                            <UncontrolledTooltip target={`iconUserDetail${row.id}`} placement="bottom">
                                Person Detail
                            </UncontrolledTooltip>
                            {row.inactive && manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Delete" && it.allow == true) && (
                                <React.Fragment>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon id={`iconUserDelete${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDeletePerson(row)} />
                                    <UncontrolledTooltip target={`iconUserDelete${row.id}`} placement="bottom">
                                        Delete Person
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                            {row.percentage >= 1 && (
                                <React.Fragment>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon id={`iconUserReport${row.id}`} icon={faPrint} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openGeneratePersonReport(row)} />
                                    <UncontrolledTooltip target={`iconUserReport${row.id}`} placement="bottom">
                                        Generate profile reports
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: mode != "ManageUser",
            },
            {
                id: "inactive",
                name: (
                    <div style={{ width: "90%" }}>
                        Active
                        <br />
                        <input type="text" name="active" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "active") ? columnSearchKeywords.find((it) => it.name == "active").value : ""} onChange={(e) => onTableFilterChange(e, "startsWith")} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.inactive,
                sortable: true,
                width: "90px",
                format: function (row, index) {
                    return row.inactive ? "Inactive" : "Active";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "inactive") ? !columnDisplaySettings.find((it) => it.columnName == "inactive").visible : true,
            },
            {
                id: "email",
                name: (
                    <div style={{ width: "90%" }}>
                        Email Address
                        <br />
                        <input type="text" name="email" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "email") ? columnSearchKeywords.find((it) => it.name == "email").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.email,
                sortable: true,
                width: "250px",
                wrap: true,
                format: function (row, index) {
                    const value = row.email;
                    return mode == "AssignSurvey" || mode == "TrackProgress" ? (
                        selectableRows ? (
                            <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                                {value}
                            </span>
                        ) : (
                            <span style={{ wordBreak: "break-word" }}>{value}</span>
                        )
                    ) : (
                        <React.Fragment>
                            <Link id={`linkUserDetail${row.id}`} to="#" onClick={() => openPersonDetail(row)}>
                                <span style={{ wordBreak: "break-word" }}>{value}</span>
                            </Link>
                            <UncontrolledTooltip target={`linkUserDetail${row.id}`} placement="bottom">
                                Person Detail
                            </UncontrolledTooltip>
                        </React.Fragment>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "email") ? !columnDisplaySettings.find((it) => it.columnName == "email").visible : true,
            },
            {
                id: "firstName",
                name: (
                    <div style={{ width: "90%" }}>
                        First
                        <br />
                        <input type="text" name="firstName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "firstName") ? columnSearchKeywords.find((it) => it.name == "firstName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.firstName,
                sortable: true,
                width: "150px",
                format: function (row, index) {
                    const value = row.firstName;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "firstName") ? !columnDisplaySettings.find((it) => it.columnName == "firstName").visible : true,
            },
            {
                id: "lastName",
                name: (
                    <div style={{ width: "90%" }}>
                        Last
                        <br />
                        <input type="text" name="lastName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lastName") ? columnSearchKeywords.find((it) => it.name == "lastName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.lastName,
                sortable: true,
                width: "150px",
                format: function (row, index) {
                    const value = row.lastName;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "lastName") ? !columnDisplaySettings.find((it) => it.columnName == "lastName").visible : true,
            },
            {
                id: "salutation",
                name: (
                    <div style={{ width: "90%" }}>
                        Preferred
                        <br />
                        <input type="text" name="salutation" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "salutation") ? columnSearchKeywords.find((it) => it.name == "salutation").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.salutation,
                sortable: true,
                width: "200px",
                wrap: true,
                format: function (row, index) {
                    const value = row.salutation;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "salutation") ? !columnDisplaySettings.find((it) => it.columnName == "salutation").visible : true,
            },
            {
                id: "position",
                name: (
                    <div style={{ width: "90%" }}>
                        Position
                        <br />
                        <input type="text" name="position" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "position") ? columnSearchKeywords.find((it) => it.name == "position").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.position,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "position") ? !columnDisplaySettings.find((it) => it.columnName == "position").visible : true,
            },
            {
                id: "team",
                name: (
                    <div style={{ width: "90%" }}>
                        Team
                        <br />
                        <input type="text" name="team" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "team") ? columnSearchKeywords.find((it) => it.name == "team").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.team,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "team") ? !columnDisplaySettings.find((it) => it.columnName == "team").visible : true,
            },
            {
                id: "strRoles",
                name: (
                    <div style={{ width: "90%" }}>
                        Roles
                        <br />
                        <input type="text" name="strRoles" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strRoles") ? columnSearchKeywords.find((it) => it.name == "strRoles").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.strRoles,
                sortable: true,
                width: "100px",
                format: function (row, index) {
                    return (
                        <React.Fragment>
                            <UserRoleCell key={`LKRole${row.id} `} person={row} roles={row.roles} generateAlert={generateAlert} personRolesUpdated={personRolesUpdated} allowEdit={allowSetAdminRole}></UserRoleCell>
                        </React.Fragment>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "strRoles") ? !columnDisplaySettings.find((it) => it.columnName == "strRoles").visible : true,
            },
            {
                id: "totalQuestions",
                name: (
                    <div style={{ width: "90%" }}>
                        Progress
                        <br />
                        <input type="text" name="progress" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "progress") ? columnSearchKeywords.find((it) => it.name == "progress").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.totalQuestions,
                sortable: true,
                width: "120px",
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.totalQuestions ? `${row.answeredQuestions} / ${row.totalQuestions}` : "0 / 0"}
                        </span>
                    ) : row.totalQuestions ? (
                        `${row.answeredQuestions} / ${row.totalQuestions}`
                    ) : (
                        "0 / 0"
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "totalQuestions") ? !columnDisplaySettings.find((it) => it.columnName == "totalQuestions").visible : true,
            },
            {
                id: "answeredQuestions",
                name: (
                    <div style={{ width: "90%" }}>
                        %
                        <br />
                        <input
                            type="text"
                            name="percentageHundred"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "percentageHundred") ? columnSearchKeywords.find((it) => it.name == "percentageHundred").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.percentage,
                sortable: true,
                width: "80px",
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.percentage ? `${toFixedIfNecessary(row.percentage * 100, 2)}%` : "0%"}
                        </span>
                    ) : row.percentage ? (
                        `${toFixedIfNecessary(row.percentage * 100, 2)}%`
                    ) : (
                        "0%"
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "answeredQuestions") ? !columnDisplaySettings.find((it) => it.columnName == "answeredQuestions").visible : true,
            },
            {
                id: "totalTimeTaken",
                name: "Time taken (mins)",
                selector: (row) => row.totalTimeTaken,
                sortable: true,
                width: "100px",
                format: function (row, index) {
                    //return toFixedIfNecessary(row.totalTimeTaken / 60, 2);
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {toFixedIfNecessary(row.totalTimeTaken / 60, 2)}
                        </span>
                    ) : (
                        toFixedIfNecessary(row.totalTimeTaken / 60, 2)
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "totalTimeTaken") ? !columnDisplaySettings.find((it) => it.columnName == "totalTimeTaken").visible : true,
            },
            {
                id: "isAssigned",
                name: (
                    <div style={{ width: "90%" }}>
                        Assigned
                        <br />
                        <input type="text" name="strAssigned" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strAssigned") ? columnSearchKeywords.find((it) => it.name == "strAssigned").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.isAssigned,
                sortable: true,
                width: "110px",
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {row.isAssigned ? "TRUE" : "FALSE"}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{row.isAssigned ? "TRUE" : "FALSE"}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "assigned") ? !columnDisplaySettings.find((it) => it.columnName == "assigned").visible : true,
            },
            {
                id: "isInvited",
                name: (
                    <div style={{ width: "90%" }}>
                        Invited
                        <br />
                        <input type="text" name="strInvited" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strInvited") ? columnSearchKeywords.find((it) => it.name == "strInvited").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.isInvited,
                sortable: true,
                width: "110px",
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {row.isInvited ? "TRUE" : "FALSE"}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{row.isInvited ? "TRUE" : "FALSE"}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "invited") ? !columnDisplaySettings.find((it) => it.columnName == "invited").visible : true,
            },
            {
                id: "messageSent",
                name: (
                    <div style={{ width: "90%" }}>
                        Sent
                        <br />
                        <input type="text" name="messageSent" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "messageSent") ? columnSearchKeywords.find((it) => it.name == "messageSent").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.messageSent,
                sortable: true,
                width: "110px",
                format: function (row, index) {
                    return row.invitationEmailMessageId && row.isInvited ? (
                        row.invitationEmailMessageStatus == "delivered" ? (
                            "TRUE"
                        ) : row.invitationEmailMessageStatus == "dropped" || row.invitationEmailMessageStatus == "drop" || row.invitationEmailMessageStatus == "deferred" || row.invitationEmailMessageStatus == "bounce" ? (
                            <a style={{ color: "#FF0000", cursor: "pointer" }} onClick={() => showMessageReason(row.invitationEmailMessageReason)}>
                                FALSE
                            </a>
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "sent") ? !columnDisplaySettings.find((it) => it.columnName == "sent").visible : true,
            },
            {
                id: "validatedDate",
                name: (
                    <div style={{ width: "90%" }}>
                        Survey Started
                        <br />
                        <input
                            type="text"
                            name="surveyStarted"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "surveyStarted") ? columnSearchKeywords.find((it) => it.name == "surveyStarted").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.validatedDate,
                sortable: true,
                sortFunction: surveyStartedSort,
                width: "110px",
                format: function (row, index) {
                    let started = false;
                    if (row.accounts && row.accounts.length > 0) {
                        const account = row.accounts.sort((b, a) => new Date(a.createdOn) - new Date(b.createdOn))[0];
                        if (account.roleId) {
                            started = true;
                        }
                    }

                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {started ? "TRUE" : "FALSE"}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{started ? "TRUE" : "FALSE"}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "surveyStarted") ? !columnDisplaySettings.find((it) => it.columnName == "surveyStarted").visible : true,
            },
            {
                id: "invitedSentOn",
                name: "Invited Date",
                selector: (row) => row.invitedSentOn,
                sortable: true,
                width: "100px",
                sortFunction: invitedSentDateSort,
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.invitedSentOn &&
                                new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.invitedSentOn))}
                        </span>
                    ) : (
                        row.invitedSentOn &&
                            new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(row.invitedSentOn))
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "invitedSentDate") ? !columnDisplaySettings.find((it) => it.columnName == "invitedSentDate").visible : true,
            },
            {
                id: "endorsedProfileCreated",
                name: (
                    <div style={{ width: "90%" }}>
                        Endorsed
                        <br />
                        <input type="text" name="endorsed" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsed") ? columnSearchKeywords.find((it) => it.name == "endorsed").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.endorsedProfileCreated,
                sortable: true,
                sortFunction: endorsedProfileCreatedSort,
                width: "110px",
                format: function (row, index) {
                    let started = row.endorsedProfileCreated;

                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {started ? "TRUE" : "FALSE"}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{started ? "TRUE" : "FALSE"}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "endorsed") ? !columnDisplaySettings.find((it) => it.columnName == "endorsed").visible : true,
            },
            {
                id: "invitedOn",
                name: "Invited Date",
                selector: (row) => row.invitedOn,
                sortable: true,
                width: "100px",
                sortFunction: invitedDateSort,
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.invitedOn &&
                                new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.invitedOn))}
                        </span>
                    ) : (
                        row.invitedOn &&
                            new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(row.invitedOn))
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "invitedDate") ? !columnDisplaySettings.find((it) => it.columnName == "invitedDate").visible : true,
            },
            {
                id: "isCandidate",
                name: (
                    <div style={{ width: "90%" }}>
                        Candidate
                        <br />
                        <input type="text" name="strCandidate" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strCandidate") ? columnSearchKeywords.find((it) => it.name == "strCandidate").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.isCandidate,
                sortable: true,
                sortFunction: candidateSort,
                width: "120px",
                format: function (row, index) {
                    return row.isCandidate ? "TRUE" : "";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "candidate") ? !columnDisplaySettings.find((it) => it.columnName == "candidate").visible : true,
            },
            {
                id: "isContractor",
                name: (
                    <div style={{ width: "90%" }}>
                        Contractor
                        <br />
                        <input
                            type="text"
                            name="strContractor"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strContractor") ? columnSearchKeywords.find((it) => it.name == "strContractor").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.isContractor,
                sortable: true,
                sortFunction: contractorSort,
                width: "120px",
                format: function (row, index) {
                    return row.isContractor ? "TRUE" : "";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "contractor") ? !columnDisplaySettings.find((it) => it.columnName == "contractor").visible : true,
            },
            {
                id: "isMentorIneligible",
                name: (
                    <div style={{ width: "90%" }}>
                        Mentor Ineligible
                        <br />
                        <input
                            type="text"
                            name="strMentorIneligible"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strMentorIneligible") ? columnSearchKeywords.find((it) => it.name == "strMentorIneligible").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.isMentorIneligible,
                sortable: true,
                sortFunction: mentorIneligibleSort,
                width: "120px",
                format: function (row, index) {
                    return row.isMentorIneligible ? "TRUE" : "";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "mentorIneligible") ? !columnDisplaySettings.find((it) => it.columnName == "mentorIneligible").visible : true,
            },
            {
                id: "selfAssessedLastProfileVersion",
                name: (
                    <div style={{ width: "90%" }}>
                        SelfAssessed Version
                        <br />
                        <input
                            type="text"
                            name="selfAssessedLastProfileVersion"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileVersion") ? columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileVersion").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.selfAssessedLastProfileVersion,
                sortable: true,
                width: "120px",
                omit: columnDisplaySettings.find((it) => it.columnName == "selfAssessedLastProfileVersion") ? !columnDisplaySettings.find((it) => it.columnName == "selfAssessedLastProfileVersion").visible : true,
            },
            {
                id: "selfAssessedLastProfileUpdatedOn",
                name: "SelfAssessed Last Updated",
                selector: (row) => row.selfAssessedLastProfileUpdatedOn,
                sortable: true,
                width: "120px",
                sortFunction: selfAssessedLastProfileUpdatedOnSort,
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.selfAssessedLastProfileUpdatedOn &&
                                new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.selfAssessedLastProfileUpdatedOn))}
                        </span>
                    ) : (
                        row.selfAssessedLastProfileUpdatedOn &&
                            new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(row.selfAssessedLastProfileUpdatedOn))
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "selfAssessedLastProfileUpdatedOn") ? !columnDisplaySettings.find((it) => it.columnName == "selfAssessedLastProfileUpdatedOn").visible : true,
            },
            {
                id: "selfAssessedLastProfileApproved",
                name: (
                    <div style={{ width: "90%" }}>
                        SelfAssessed Approved
                        <br />
                        <input
                            type="text"
                            name="selfAssessedLastProfileApproved"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileApproved") ? columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileApproved").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.selfAssessedLastProfileApproved,
                sortable: true,
                width: "120px",
                format: function (row, index) {
                    let approved = row.selfAssessedLastProfileApproved;

                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {approved}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{approved}</span>
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "selfAssessedLastProfileApproved") ? !columnDisplaySettings.find((it) => it.columnName == "selfAssessedLastProfileApproved").visible : true,
            },
            {
                id: "endorsementLastUpdatedVersion",
                name: (
                    <div style={{ width: "90%" }}>
                        Endorsed Version
                        <br />
                        <input
                            type="text"
                            name="endorsementLastUpdatedVersion"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsementLastUpdatedVersion") ? columnSearchKeywords.find((it) => it.name == "endorsementLastUpdatedVersion").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.endorsementLastUpdatedVersion,
                sortable: true,
                width: "120px",
                omit: columnDisplaySettings.find((it) => it.columnName == "endorsementLastUpdatedVersion") ? !columnDisplaySettings.find((it) => it.columnName == "endorsementLastUpdatedVersion").visible : true,
            },
            {
                id: "endorsementLastUpdated",
                name: "Endorsed Last Updated",
                selector: (row) => row.endorsementLastUpdated,
                sortable: true,
                width: "120px",
                sortFunction: endorsementLastUpdatedSort,
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.endorsementLastUpdated &&
                                new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.endorsementLastUpdated))}
                        </span>
                    ) : (
                        row.endorsementLastUpdated &&
                            new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(row.endorsementLastUpdated))
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "endorsementLastUpdated") ? !columnDisplaySettings.find((it) => it.columnName == "endorsementLastUpdated").visible : true,
            },
            {
                id: "endorsementLatestStatus",
                name: (
                    <div style={{ width: "90%" }}>
                        Endorsed Status
                        <br />
                        <input
                            type="text"
                            name="endorsementLatestStatus"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsementLatestStatus") ? columnSearchKeywords.find((it) => it.name == "endorsementLatestStatus").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.endorsementLatestStatus,
                sortable: true,
                sortFunction: endorsementLatestStatusSort,
                width: "150px",
                format: function (row, index) {
                    return row.endorsementLatestStatus;
                },
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "endorsementLatestStatus") ? !columnDisplaySettings.find((it) => it.columnName == "endorsementLatestStatus").visible : true,
            },
            {
                id: "location",
                name: (
                    <div style={{ width: "90%" }}>
                        Location
                        <br />
                        <input type="text" name="location" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "location") ? columnSearchKeywords.find((it) => it.name == "location").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.location,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "location") ? !columnDisplaySettings.find((it) => it.columnName == "location").visible : true,
            },
            {
                id: "country",
                name: (
                    <div style={{ width: "90%" }}>
                        Country
                        <br />
                        <input type="text" name="country" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "country") ? columnSearchKeywords.find((it) => it.name == "country").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.country,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "country") ? !columnDisplaySettings.find((it) => it.columnName == "country").visible : true,
            },
            {
                id: "other",
                name: (
                    <div style={{ width: "90%" }}>
                        Other
                        <br />
                        <input type="text" name="other" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "other") ? columnSearchKeywords.find((it) => it.name == "other").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.other,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "other") ? !columnDisplaySettings.find((it) => it.columnName == "other").visible : true,
            },
            {
                id: "leaveOn",
                name: "LeaveOn",
                selector: (row) => row.leaveOn,
                sortable: true,
                width: "120px",
                sortFunction: leaveOnSort,
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ cursor: "default" }}>
                            {row.leaveOn &&
                                new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.leaveOn))}
                        </span>
                    ) : (
                        row.leaveOn &&
                            new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(row.leaveOn))
                    );
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "leaveOn") ? !columnDisplaySettings.find((it) => it.columnName == "leaveOn").visible : true,
            },
            {
                id: "department",
                name: (
                    <div style={{ width: "90%" }}>
                        Department
                        <br />
                        <input type="text" name="department" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "department") ? columnSearchKeywords.find((it) => it.name == "department").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.department,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "department") ? !columnDisplaySettings.find((it) => it.columnName == "department").visible : true,
            },
            {
                id: "businessUnit",
                name: (
                    <div style={{ width: "90%" }}>
                        Business Unit
                        <br />
                        <input type="text" name="businessUnit" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "businessUnit") ? columnSearchKeywords.find((it) => it.name == "businessUnit").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.businessUnit,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "businessUnit") ? !columnDisplaySettings.find((it) => it.columnName == "businessUnit").visible : true,
            },
            {
                id: "division",
                name: (
                    <div style={{ width: "90%" }}>
                        Division
                        <br />
                        <input type="text" name="division" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "division") ? columnSearchKeywords.find((it) => it.name == "division").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.division,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "division") ? !columnDisplaySettings.find((it) => it.columnName == "division").visible : true,
            },
            {
                id: "territory",
                name: (
                    <div style={{ width: "90%" }}>
                        Territory
                        <br />
                        <input type="text" name="territory" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "territory") ? columnSearchKeywords.find((it) => it.name == "territory").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.territory,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "territory") ? !columnDisplaySettings.find((it) => it.columnName == "territory").visible : true,
            },
            {
                id: "costCentre",
                name: (
                    <div style={{ width: "90%" }}>
                        Cost Centre
                        <br />
                        <input type="text" name="costCentre" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "costCentre") ? columnSearchKeywords.find((it) => it.name == "costCentre").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.costCentre,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "costCentre") ? !columnDisplaySettings.find((it) => it.columnName == "costCentre").visible : true,
            },
            {
                id: "supervisor",
                name: (
                    <div style={{ width: "90%" }}>
                        Supervisor
                        <br />
                        <input type="text" name="supervisor" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "supervisor") ? columnSearchKeywords.find((it) => it.name == "supervisor").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.supervisor,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "supervisor") ? !columnDisplaySettings.find((it) => it.columnName == "supervisor").visible : true,
            },
            {
                id: "skipSelfAssessmentSurvey",
                name: (
                    <div style={{ width: "90%" }}>
                        Skip Survey
                        <br />
                        <input
                            type="text"
                            name="strSkipSelfAssessmentSurvey"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strSkipSelfAssessmentSurvey") ? columnSearchKeywords.find((it) => it.name == "strSkipSelfAssessmentSurvey").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.skipSelfAssessmentSurvey,
                sortable: true,
                width: "110px",
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {row.skipSelfAssessmentSurvey ? "TRUE" : "FALSE"}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{row.skipSelfAssessmentSurvey ? "TRUE" : "FALSE"}</span>
                    );
                },
                omit: !(config && config.allowSkipSelfAssessmentSurvey) || (columnDisplaySettings.find((it) => it.columnName == "skipSelfAssessmentSurvey") ? !columnDisplaySettings.find((it) => it.columnName == "skipSelfAssessmentSurvey").visible : true),
            },
            {
                id: "roleBasedSurveyId",
                name: (
                    <div style={{ width: "90%" }}>
                        Role Based Survey
                        <br />
                        <input
                            type="text"
                            name="roleBasedSurvey"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "roleBasedSurvey") ? columnSearchKeywords.find((it) => it.name == "roleBasedSurvey").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.roleBasedSurveyId,
                sortable: true,
                width: "150px",
                format: function (row, index) {
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {row.roleBasedSurvey}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{row.roleBasedSurvey}</span>
                    );
                },
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "roleBasedSurvey") ? !columnDisplaySettings.find((it) => it.columnName == "roleBasedSurvey").visible : true,
            },
            {
                id: "fte",
                name: (
                    <div style={{ width: "90%" }}>
                        Fte
                        <br />
                        <input type="text" name="fte" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "fte") ? columnSearchKeywords.find((it) => it.name == "fte").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.fte,
                sortable: true,
                width: "110px",
                format: function (row, index) {
                    return row.fte ? (row.fte % 1 != 0 ? Math.round(row.fte * 100) / 100 : row.fte.toFixed(1)) : "1.0";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "fte") ? !columnDisplaySettings.find((it) => it.columnName == "fte").visible : true,
            },
            {
                id: "miscellaneous1",
                name: (
                    <div style={{ width: "90%" }}>
                        {config && config.personMiscellaneous1Enable ? config.personMiscellaneous1Caption : "Miscellaneous 1"}
                        <br />
                        <input
                            type="text"
                            name="miscellaneous1"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "miscellaneous1") ? columnSearchKeywords.find((it) => it.name == "miscellaneous1").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.miscellaneous1,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    const value = row.miscellaneous1;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: config && config.personMiscellaneous1Enable ? (columnDisplaySettings.find((it) => it.columnName == "miscellaneous1") ? !columnDisplaySettings.find((it) => it.columnName == "miscellaneous1").visible : true) : true,
            },
            {
                id: "miscellaneous2",
                name: (
                    <div style={{ width: "90%" }}>
                        {config && config.personMiscellaneous2Enable ? config.personMiscellaneous2Caption : "Miscellaneous 2"}
                        <br />
                        <input
                            type="text"
                            name="miscellaneous2"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "miscellaneous2") ? columnSearchKeywords.find((it) => it.name == "miscellaneous2").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.miscellaneous2,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    const value = row.miscellaneous2;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: config && config.personMiscellaneous2Enable ? (columnDisplaySettings.find((it) => it.columnName == "miscellaneous2") ? !columnDisplaySettings.find((it) => it.columnName == "miscellaneous2").visible : true) : true,
            },
            {
                id: "miscellaneous3",
                name: (
                    <div style={{ width: "90%" }}>
                        {config && config.personMiscellaneous3Enable ? config.personMiscellaneous3Caption : "Miscellaneous 3"}
                        <br />
                        <input
                            type="text"
                            name="miscellaneous3"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "miscellaneous3") ? columnSearchKeywords.find((it) => it.name == "miscellaneous3").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.miscellaneous3,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    const value = row.miscellaneous3;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: config && config.personMiscellaneous3Enable ? (columnDisplaySettings.find((it) => it.columnName == "miscellaneous3") ? !columnDisplaySettings.find((it) => it.columnName == "miscellaneous3").visible : true) : true,
            },
            {
                id: "miscellaneous4",
                name: (
                    <div style={{ width: "90%" }}>
                        {config && config.personMiscellaneous4Enable ? config.personMiscellaneous4Caption : "Miscellaneous 4"}
                        <br />
                        <input
                            type="text"
                            name="miscellaneous4"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "miscellaneous4") ? columnSearchKeywords.find((it) => it.name == "miscellaneous4").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.miscellaneous4,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    const value = row.miscellaneous4;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: config && config.personMiscellaneous4Enable ? (columnDisplaySettings.find((it) => it.columnName == "miscellaneous4") ? !columnDisplaySettings.find((it) => it.columnName == "miscellaneous4").visible : true) : true,
            },
            {
                id: "miscellaneous5",
                name: (
                    <div style={{ width: "90%" }}>
                        {config && config.personMiscellaneous5Enable ? config.personMiscellaneous5Caption : "Miscellaneous 5"}
                        <br />
                        <input
                            type="text"
                            name="miscellaneous5"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "miscellaneous5") ? columnSearchKeywords.find((it) => it.name == "miscellaneous5").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.miscellaneous5,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    const value = row.miscellaneous5;
                    return selectableRows ? (
                        <span onClick={(e) => handleRowClick(row)} style={{ wordBreak: "break-word", cursor: "default" }}>
                            {value}
                        </span>
                    ) : (
                        <span style={{ wordBreak: "break-word" }}>{value}</span>
                    );
                },
                omit: config && config.personMiscellaneous5Enable ? (columnDisplaySettings.find((it) => it.columnName == "miscellaneous5") ? !columnDisplaySettings.find((it) => it.columnName == "miscellaneous5").visible : true) : true,
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderDeletePersonFirstConfirmation()}
                {this.renderDeletePersonSecondConfirmation()}
                {this.renderAssignInviteConfirmationDialog()}
                {this.renderContinueToInviteDialog()}
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <UserBulkUpload showDialog={this.state.showDialogTs} generateAlert={this.generateAlert}></UserBulkUpload>
                        <UserBulkUploadProfiles showDialog={this.state.showDialogImportProfileTs} generateAlert={this.generateAlert}></UserBulkUploadProfiles>
                        <GenerateUserProfileReports showDialog={this.state.showDialogUserReportTs} person={this.state.selectedPerson} generateAlert={this.generateAlert}></GenerateUserProfileReports>
                        <UserAddNew showDialog={this.state.showDialogNewUserTs} generateAlert={this.generateAlert} config={this.props.config}></UserAddNew>
                        <UserDetail
                            selectedPerson={this.state.selectedPerson}
                            showDialog={this.state.showDialogUserDetailTs}
                            generateAlert={this.generateAlert}
                            allowEdit={manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Edit" && it.allow == true) ? true : false}
                            allowUpdateAccess={manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Update Access Permission" && it.allow == true) ? true : false}
                            allowSetAdminRole={manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Set Admin Role" && it.allow == true) ? true : false}
                            handleUpdatePersonAdminRoleName={this.handleUpdatePersonAdminRoleName}
                        ></UserDetail>
                        <UserUpdatePersonMiscellaneousConfig showDialog={this.state.showDialogUpdatePersonMiscellaneousConfigTs} generateAlert={this.generateAlert}></UserUpdatePersonMiscellaneousConfig>
                        {this.props.mode == "AssignSurvey" ? (
                            <React.Fragment>
                                {this.props.config && (
                                    <React.Fragment>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label
                                                sm={12}
                                                dangerouslySetInnerHTML={{
                                                    __html: `Your license count is <b>${this.props.config.licenceAllocations}</b> with a renewal date of <b>${
                                                        this.props.config.renewalDate &&
                                                        new Intl.DateTimeFormat("en-GB", {
                                                            year: "2-digit",
                                                            month: "long",
                                                            day: "numeric",
                                                        }).format(new Date(this.props.config.renewalDate))
                                                    }</b> and you currently have <b>${this.props.config.liveUsers}</b> live users`,
                                                }}
                                            ></Label>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={12}></Label>
                                        </FormGroup>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, personsSelected: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", personsSelected: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {mode == "ManageUser" && (
                                        <React.Fragment>
                                            {manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Add" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleAddNew}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.personsLoading}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} /> Add New
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                            {manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Export" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleExportIndividuals}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.personsLoading || this.props.loadingPrint}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Export
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                            {manageUserPageAssignment && manageUserPageAssignment.operations.find((it) => it.operation == "Import" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleImportIndividuals}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.personsLoading}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Import
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {mode == "AssignSurvey" && (
                                        <React.Fragment>
                                            {!(this.state.assignMode || this.state.unassignMode || this.state.inviteMode) && (
                                                <React.Fragment>
                                                    {assignUserPageAssignment && assignUserPageAssignment.operations.find((it) => it.operation == "Assign Participants" && it.allow == true) && (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={this.handleFilterAssign}
                                                                style={{
                                                                    fontSize: "16px",
                                                                    height: "40px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                disabled={this.props.personsLoading}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck} /> Assign
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                        </React.Fragment>
                                                    )}
                                                    {assignUserPageAssignment && assignUserPageAssignment.operations.find((it) => it.operation == "Invite Participants" && it.allow == true) && (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={this.handleFilterInvite}
                                                                style={{
                                                                    fontSize: "16px",
                                                                    height: "40px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                disabled={this.props.personsLoading}
                                                            >
                                                                <FontAwesomeIcon icon={faEnvelope} /> Invite
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                        </React.Fragment>
                                                    )}
                                                    {assignUserPageAssignment && assignUserPageAssignment.operations.find((it) => it.operation == "Un-Assign Participants" && it.allow == true) && (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={this.handleFilterUnAssign}
                                                                style={{
                                                                    fontSize: "16px",
                                                                    height: "40px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                disabled={this.props.personsLoading}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} /> Un-Assign
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {(this.state.assignMode || this.state.unassignMode || this.state.inviteMode) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleProcessAssignInvite}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.state.personsSelected.length == 0 || this.state.isUpdating}
                                                    >
                                                        {`Process ${this.state.assignMode ? "Assign" : this.state.unassignMode ? "Un-Assign" : "Invite"}`}
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleCancelAssignInvite}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.state.isUpdating}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    {this.state.inviteMode && (
                                                        <React.Fragment>
                                                            <Input type="checkbox" id="chkShowAllInvited" checked={this.state.checkShowInvited} onChange={this.handleShowAllInviteChanged} />{" "}
                                                            <Label check for="chkShowAllInvited">
                                                                Show All
                                                            </Label>
                                                            &nbsp;&nbsp;
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {mode == "TrackProgress" && (
                                        <React.Fragment>
                                            {trackProgressPageAssignment && trackProgressPageAssignment.operations.find((it) => it.operation == "Export" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleExportIndividuals}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.personsLoading || this.props.loadingPrint}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Export
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                            {trackProgressPageAssignment && trackProgressPageAssignment.operations.find((it) => it.operation == "Export Person Profiles" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleExportProfiles}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.personsLoading || this.props.loadingPrint || this.state.exportProfileMode}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Export Person Profiles
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    {this.state.exportProfileMode && (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={this.handleProcessExportProfiles}
                                                                style={{
                                                                    fontSize: "16px",
                                                                    height: "40px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                disabled={this.state.personsSelected.length == 0 || this.state.isUpdating}
                                                            >
                                                                Process
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                color="primary"
                                                                onClick={this.handleCancelExportProfiles}
                                                                style={{
                                                                    fontSize: "16px",
                                                                    height: "40px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                disabled={this.state.isUpdating}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {trackProgressPageAssignment && trackProgressPageAssignment.operations.find((it) => it.operation == "Import Persons and Profiles" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleImportProfiles}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.personsLoading || this.props.loadingPrint}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Import Person Profiles
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <React.Fragment>
                                        <Button
                                            color={this.state.showColumnSettings ? "success" : "primary"}
                                            onClick={this.handleShowColumnSettings}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                                marginBottom: "10px",
                                            }}
                                            disabled={this.props.personsLoading}
                                        >
                                            <FontAwesomeIcon icon={faCog} /> Columns
                                        </Button>
                                        &nbsp;&nbsp;
                                    </React.Fragment>
                                    {this.props.config && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.openUpdatePersonMiscellaneousConfigDialog}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading}
                                            >
                                                <FontAwesomeIcon icon={faCog} /> Additional Columns
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        {this.state.showColumnSettings && (
                            <div style={{ marginBottom: "10px" }}>
                                <ColumnDisplaySetting pageName={this.props.mode == "ManageUser" ? "Manage Users" : this.props.mode == "AssignSurvey" ? "Assign Participants" : "Track Participant Progress"}></ColumnDisplaySetting>
                            </div>
                        )}
                        <SkillsTxTable
                            columns={columns}
                            data={this.state.persons}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            columnSearchKeywords={columnSearchKeywords}
                            searchProperties={this.searchProperties()}
                            selectableRows={mode == "AssignSurvey" && (this.state.assignMode || this.state.unassignMode || this.state.inviteMode) ? true : false}
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClick}
                            progressPending={this.props.personsLoading}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        personsLoading: state.adminPerson.loading,
        personsError: state.adminPerson.error,
        persons: state.adminPerson.persons,
        isUpdating: state.adminPerson.isUpdating,

        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,

        pageAssignments: state.adminPerson.pageAssignments,
        columnDisplaySettings: state.adminPerson.columnDisplaySettings,
        config: state.config.config,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
