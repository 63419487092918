import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    adminRoles: [],
    adminRolePageAssignments: null,
};

const getAdminRolesStart = (state) => {
    return updateState(state, { error: null, adminRoles: [], loading: true });
};

const getAdminRolesSuccess = (state, action) => {
    return updateState(state, {
        adminRoles: [...action.adminRoles],
        error: null,
        loading: false,
    });
};

const getAdminRolesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const getAdminRolePageAssignmentsStart = (state) => {
    return updateState(state, { error: null, adminRolePageAssignments: null, loading: true });
};

const getAdminRolePageAssignmentsSuccess = (state, action) => {
    return updateState(state, {
        adminRolePageAssignments: [...action.pageAssignments],
        error: null,
        loading: false,
    });
};

const getAdminRolePageAssignmentsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const createAdminRoleStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createAdminRoleSuccess = (state, action) => {
    let adminRoles = state.adminRoles;

    if (adminRoles) {
        adminRoles.push(action.adminRole);
    } else {
        adminRoles = [action.adminRole];
    }

    return updateState(state, {
        adminRoles: [...adminRoles],
        error: null,
        isUpdating: false,
    });
};

const createAdminRoleFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateAdminRolePageAssignmentsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateAdminRolePageAssignmentsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
    });
};

const updateAdminRolePageAssignmentsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteAdminRoleStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteAdminRoleSuccess = (state, action) => {
    let adminRoles = state.adminRoles;

    const deletedAdminRole = adminRoles.find((it) => it.id == action.adminRoleId);

    if (deletedAdminRole) {
        const index = adminRoles.indexOf(deletedAdminRole);
        if (index !== -1) {
            adminRoles.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        adminRoles: [...adminRoles],
    });
};

const deleteAdminRoleFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const renameAdminRoleStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const renameAdminRoleSuccess = (state, action) => {
    let adminRoles = state.adminRoles;

    let updatedAdminRole = adminRoles.find((it) => it.id == action.adminRoleId);

    if (updatedAdminRole) {
        updatedAdminRole.name = action.name;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        adminRoles: [...adminRoles],
    });
};

const renameAdminRoleFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ADMIN_ROLES_START:
            return getAdminRolesStart(state);
        case actionTypes.GET_ADMIN_ROLES_SUCCESS:
            return getAdminRolesSuccess(state, action);
        case actionTypes.GET_ADMIN_ROLES_FAIL:
            return getAdminRolesFail(state, action);

        case actionTypes.GET_ADMIN_ROLE_PAGE_ASSIGNMENTS_START:
            return getAdminRolePageAssignmentsStart(state);
        case actionTypes.GET_ADMIN_ROLE_PAGE_ASSIGNMENTS_SUCCESS:
            return getAdminRolePageAssignmentsSuccess(state, action);
        case actionTypes.GET_ADMIN_ROLE_PAGE_ASSIGNMENTS_FAIL:
            return getAdminRolePageAssignmentsFail(state, action);

        case actionTypes.ADD_ADMIN_ROLE_START:
            return createAdminRoleStart(state);
        case actionTypes.ADD_ADMIN_ROLE_SUCCESS:
            return createAdminRoleSuccess(state, action);
        case actionTypes.ADD_ADMIN_ROLE_FAIL:
            return createAdminRoleFail(state, action);

        case actionTypes.UPDATE_ADMIN_ROLE_PAGE_ASSIGNMENTS_START:
            return updateAdminRolePageAssignmentsStart(state);
        case actionTypes.UPDATE_ADMIN_ROLE_PAGE_ASSIGNMENTS_SUCCESS:
            return updateAdminRolePageAssignmentsSuccess(state, action);
        case actionTypes.UPDATE_ADMIN_ROLE_PAGE_ASSIGNMENTS_FAIL:
            return updateAdminRolePageAssignmentsFail(state, action);

        case actionTypes.DELETE_ADMIN_ROLE_START:
            return deleteAdminRoleStart(state);
        case actionTypes.DELETE_ADMIN_ROLE_SUCCESS:
            return deleteAdminRoleSuccess(state, action);
        case actionTypes.DELETE_ADMIN_ROLE_FAIL:
            return deleteAdminRoleFail(state, action);

        case actionTypes.RENAME_ADMIN_ROLE_START:
            return renameAdminRoleStart(state);
        case actionTypes.RENAME_ADMIN_ROLE_SUCCESS:
            return renameAdminRoleSuccess(state, action);
        case actionTypes.RENAME_ADMIN_ROLE_FAIL:
            return renameAdminRoleFail(state, action);

        default:
            return state;
    }
};

export default reducer;
