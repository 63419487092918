import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

class AdminRoleOverwriteConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        if (showDialog) {
            this.props.reopenManageAdminRole();
        }
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleConfirm = () => {
        this.setState({
            showDialog: false,
        });
        this.props.handleConfirmOverwrite();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}></ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>
                                            Are you sure you want to delete this admin role?
                                            <br />
                                            NOTE: All admin users previously assigned will be unaffected.
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handleConfirm();
                                }}
                            >
                                Ok
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoleOverwriteConfirmation);
