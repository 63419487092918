import * as countryActions from "../../../store/actions/countryAction";
import * as jobActions from "../../../store/actions/jobAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import changeInput from "../../../utils/changeInput";
import { formatDate_localeString } from "../../../utils/stringUtils";
import UserPageAssignments from "./UserPageAssignments";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

class UserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showDialog: false,
            isUpdating: false,
            countries: [],
            jobs: [],
            optionYesOrNo: [
                {
                    label: "Yes",
                    value: true,
                },
                {
                    label: "No",
                    value: false,
                },
            ],
            form: {
                active: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                usePreferredName: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                preferredName: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                position: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                team: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                country: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                location: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                other: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roles: {
                    validation: {
                        required: false,
                    },
                    value: [],
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                validatedDate: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                leaveOn: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                department: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                businessUnit: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                division: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                territory: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                costCentre: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                supervisor: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                isCandidate: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                isContractor: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                isMentorIneligible: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                isAnalyticsUser: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                miscellaneous1: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                miscellaneous2: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                miscellaneous3: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                miscellaneous4: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                miscellaneous5: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                skipSelfAssessmentSurvey: {
                    validation: {
                        required: false,
                        isBool: true,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurveyId: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurvey: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                fte: {
                    validation: {
                        isUserFte: true,
                        required: true,
                    },
                    value: "1.0",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            selectedPerson: this.props.selectedPerson,
            showMoreDetails: false,
            editMode: false,
            dateValue: new Date().toISOString(),
            showDialogUserPageAssignmentsTs: null,
        };

        this.formatDate_localeString = formatDate_localeString.bind(this);
    }

    componentDidMount() {
        Promise.all([this.props.onJob.getJobs(), this.props.onCountry.getCountries()]).then(() => {
            let countries = [
                {
                    label: "Select Country",
                    value: "",
                },
            ];
            if (this.props.countries) {
                countries.push(...this.props.countries.map((country) => ({ label: country.name, value: country.name })));
            }

            let jobs = [
                {
                    label: "Select Role",
                    value: "",
                },
            ];
            if (this.props.jobs) {
                jobs.push(
                    ...this.props.jobs
                        .filter((it) => it.inActive != true && it.reference && !it.isSample)
                        .sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))
                        .map((job) => ({ label: job.name, value: job.id }))
                );
            }
            this.setState({ countries, jobs });
            this.setState({ loading: this.props.countryLoading || this.props.jobsLoading });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.selectedPerson !== this.props.selectedPerson) {
            this.setState({ selectedPerson: this.props.selectedPerson });
        }

        if (prevProps.resetPasswordSent !== this.props.resetPasswordSent && this.props.resetPasswordSent) {
            this.props.generateAlert("success", "Reset password request sent");
        }
    }

    prepareFormState = () => {
        const person = this.props.selectedPerson;
        if (person) {
            this.setState({
                form: {
                    active: {
                        validation: {
                            required: true,
                        },
                        value: person.inactive ? "Inactive" : "Active",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    firstName: {
                        validation: {
                            required: true,
                        },
                        value: person.firstName,
                        valid: person && person.firstName && person.firstName != "" ? true : false,
                        isValidFormat: person && person.firstName && person.firstName != "" ? true : false,
                        touched: false,
                    },
                    lastName: {
                        validation: {
                            required: true,
                        },
                        value: person.lastName,
                        valid: person && person.lastName && person.lastName != "" ? true : false,
                        isValidFormat: person && person.lastName && person.lastName != "" ? true : false,
                        touched: false,
                    },
                    email: {
                        validation: {
                            required: true,
                            isEmail: true,
                        },
                        value: person.email,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    usePreferredName: {
                        validation: {
                            required: false,
                        },
                        value: person.usePreferredName,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    preferredName: {
                        validation: {
                            required: false,
                        },
                        value: person.salutation,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    position: {
                        validation: {
                            required: false,
                        },
                        value: person.position,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    team: {
                        validation: {
                            required: false,
                        },
                        value: person.team,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    country: {
                        validation: {
                            required: false,
                        },
                        value: person.country,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    location: {
                        validation: {
                            required: false,
                        },
                        value: person.location,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    other: {
                        validation: {
                            required: false,
                        },
                        value: person.other,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    roles: {
                        validation: {
                            required: false,
                        },
                        value: person.roles
                            ? person.roles.map((it) => {
                                  return it.role;
                              })
                            : [],
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    validatedDate: {
                        validation: {
                            required: false,
                        },
                        value: person.validatedDate,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    leaveOn: {
                        validation: {
                            required: false,
                        },
                        value: person.leaveOn,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    department: {
                        validation: {
                            required: false,
                        },
                        value: person.department,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    businessUnit: {
                        validation: {
                            required: false,
                        },
                        value: person.businessUnit,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    division: {
                        validation: {
                            required: false,
                        },
                        value: person.division,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    territory: {
                        validation: {
                            required: false,
                        },
                        value: person.territory,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    costCentre: {
                        validation: {
                            required: false,
                        },
                        value: person.costCentre,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    supervisor: {
                        validation: {
                            required: false,
                        },
                        value: person.supervisor,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    isCandidate: {
                        validation: {
                            required: false,
                        },
                        value: person.isCandidate,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    isContractor: {
                        validation: {
                            required: false,
                        },
                        value: person.isContractor,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    isMentorIneligible: {
                        validation: {
                            required: false,
                        },
                        value: person.isMentorIneligible,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    isAnalyticsUser: {
                        validation: {
                            required: false,
                        },
                        value: person.isAnalyticsUser,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    miscellaneous1: {
                        validation: {
                            required: false,
                        },
                        value: person.miscellaneous1,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    miscellaneous2: {
                        validation: {
                            required: false,
                        },
                        value: person.miscellaneous2,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    miscellaneous3: {
                        validation: {
                            required: false,
                        },
                        value: person.miscellaneous3,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    miscellaneous4: {
                        validation: {
                            required: false,
                        },
                        value: person.miscellaneous4,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    miscellaneous5: {
                        validation: {
                            required: false,
                        },
                        value: person.miscellaneous5,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    skipSelfAssessmentSurvey: {
                        validation: {
                            required: false,
                            isBool: true,
                        },
                        value: person.skipSelfAssessmentSurvey && person.skipSelfAssessmentSurvey == true ? true : false,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    roleBasedSurveyId: {
                        validation: {
                            required: false,
                        },
                        value: person.roleBasedSurveyId,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    roleBasedSurvey: {
                        validation: {
                            required: false,
                        },
                        value: person.roleBasedSurvey,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    fte: {
                        validation: {
                            isUserFte: true,
                            required: true,
                        },
                        value: person && person.fte ? (person.fte % 1 != 0 ? Math.round(person.fte * 100) / 100 : person.fte.toFixed(1)) : "1.0",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                },
                isFormValid: person && person.firstName && person.firstName != "" && person.lastName && person.lastName != "" ? true : false,
                isAdminPerson: person.roles.find((it) => it.role == "Admin") ? true : false,
            });
        }
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
            editMode: false,
        });
    };

    handleChange = (event) => {
        const { jobs } = this.state;
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "roleBasedSurveyId") {
            const job = jobs.find((it) => it.value == value);
            if (job) {
                updateState = changeInput(updateState.form, "roleBasedSurvey", job.label);
            } else {
                updateState = changeInput(updateState.form, "roleBasedSurvey", "");
            }
        } else if (name == "skipSelfAssessmentSurvey" && value == "true") {
            updateState = changeInput(updateState.form, "roleBasedSurvey", null);
            updateState = changeInput(updateState.form, "roleBasedSurveyId", "");
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        let updateState = changeInput(this.state.form, name, checked);

        if (name == "isContractor" && checked) {
            updateState = changeInput(updateState.form, "isCandidate", false);
        } else if (name == "isCandidate" && checked) {
            updateState = changeInput(updateState.form, "isContractor", false);
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeCheckBoxRole = (role) => {
        const form = this.state.form;

        if (form.roles.value.includes(role)) {
            const index = form.roles.value.indexOf(role);
            form.roles.value.splice(index, 1);
        } else {
            form.roles.value.push(role);
        }

        this.setState({ form });
    };

    handleDateChange = (name, value) => {
        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    togleShowEdit = () => {
        const editMode = this.state.editMode;

        this.setState({ editMode: !editMode });

        if (editMode) {
            this.prepareFormState();
        }
    };

    handlePerformSave = () => {
        const form = this.state.form;
        const person = {
            inactive: form.active.value == "Active" ? false : true,
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            email: form.email.value,
            usePreferredName: form.usePreferredName.value,
            salutation: form.preferredName.value,
            position: form.position.value,
            team: form.team.value,
            location: form.location.value,
            country: form.country.value,
            countryCode: form.country.value,
            other: form.other.value,
            roles: form.roles.value,
            validatedDate: form.validatedDate.value,
            leaveOn: form.leaveOn.value,
            department: form.department.value,
            businessUnit: form.businessUnit.value,
            division: form.division.value,
            territory: form.territory.value,
            costCentre: form.costCentre.value,
            supervisor: form.supervisor.value,
            isCandidate: form.isCandidate.value,
            isContractor: form.isContractor.value,
            isMentorIneligible: form.isMentorIneligible.value,
            isAnalyticsUser: form.isAnalyticsUser.value,
            miscellaneous1: form.miscellaneous1.value,
            miscellaneous2: form.miscellaneous2.value,
            miscellaneous3: form.miscellaneous3.value,
            miscellaneous4: form.miscellaneous4.value,
            miscellaneous5: form.miscellaneous5.value,
            skipSelfAssessmentSurvey: form.skipSelfAssessmentSurvey.value,
            roleBasedSurveyId: form.roleBasedSurveyId.value,
            roleBasedSurvey: form.roleBasedSurvey.value,
            fte: form.fte.value,
        };

        this.setState({ isUpdating: true });

        this.props.onPerson.updatePerson(this.props.selectedPerson.id, person).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.personsError) {
                    this.props.generateAlert("success", "Update person success.");
                    this.togleDialogModal();

                    if (form.roles.value.includes("Admin") && (!this.props.selectedPerson.havePassword || this.props.selectedPerson.havePassword == false) && (!this.props.selectedPerson.resetPasswordRequested || this.props.selectedPerson.resetPasswordRequested == false)) {
                        if (window.confirm("Do you want to send reset password email to this new admin user?")) {
                            this.props.onPerson.resetPersonPassword(this.props.selectedPerson.id).then(() => {});
                        }
                    }
                } else {
                    this.props.generateAlert("danger", this.props.personsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleShowMoreDetail = () => {
        const showMoreDetails = this.state.showMoreDetails;

        this.setState({ showMoreDetails: !showMoreDetails });
    };

    openPersonPageAssignments = () => {
        this.setState({ showDialogUserPageAssignmentsTs: new Date(), showDialog: false });
    };

    reopenPersonDetail = (updatedAdminRoleName) => {
        const person = this.props.selectedPerson;

        this.setState({ showDialog: true });

        if (updatedAdminRoleName && person.adminRoleName != updatedAdminRoleName && this.props.handleUpdatePersonAdminRoleName) {
            this.props.handleUpdatePersonAdminRoleName(person.id, updatedAdminRoleName);
        }
    };

    render() {
        const person = this.props.selectedPerson;
        const { config } = this.props;
        const { form, editMode, countries, jobs, selectedPerson, isAdminPerson, optionYesOrNo } = this.state;

        const validatedDate = form.validatedDate && form.validatedDate.value ? this.formatDate_localeString(new Date(form.validatedDate.value)) : null;

        const leaveOn = form.leaveOn && form.leaveOn.value ? this.formatDate_localeString(new Date(form.leaveOn.value)) : null;

        return (
            <React.Fragment>
                {this.props.allowUpdateAccess && isAdminPerson && <UserPageAssignments reopenPersonDetail={this.reopenPersonDetail} selectedPerson={this.state.selectedPerson} showDialog={this.state.showDialogUserPageAssignmentsTs} generateAlert={this.props.generateAlert}></UserPageAssignments>}
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Person Detail</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Active</Label>
                                        <Col sm={8}>
                                            <Input type="select" name="active" id="inputActive" value={form.active.value} invalid={form.active.touched && !form.active.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                <option key={1} value={"Active"}>
                                                    {"Active"}
                                                </option>
                                                <option key={2} value={"Inactive"}>
                                                    {"Inactive"}
                                                </option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Email</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="email" id="email" value={form.email.value} invalid={form.email.touched && !form.email.isValidFormat} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                            {form.email.value != "" && !form.email.isValidFormat && <FormFeedback>Incorrect email format</FormFeedback>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>First Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="firstName" id="inputFirstName" value={form.firstName.value ? form.firstName.value : ""} invalid={editMode && !form.firstName.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Last Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="lastName" id="lastName" value={form.lastName.value ? form.lastName.value : ""} invalid={editMode && !form.lastName.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={4}></Col>
                                        <Col sm={8} style={{ height: "38px", paddingTop: "10px" }}>
                                            <Input
                                                type="checkbox"
                                                name="usePreferredName"
                                                id="usePreferredName"
                                                checked={form.usePreferredName.value}
                                                invalid={form.usePreferredName.touched && !form.usePreferredName.valid}
                                                style={{ marginRight: "10px" }}
                                                disabled={!editMode || this.state.isUpdating}
                                                onChange={this.handleChangeCheckBox}
                                            />
                                            <Label for="usePreferredName" check>
                                                Use Preferred Name
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Preferred</Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="preferredName"
                                                id="preferredName"
                                                value={form.preferredName.value ? form.preferredName.value : ""}
                                                invalid={form.preferredName.touched && !form.preferredName.valid}
                                                onChange={this.handleChange}
                                                disabled={!editMode || this.state.isUpdating}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {this.props.config && this.props.config.allowSkipSelfAssessmentSurvey && (
                                        <FormGroup row>
                                            <Label sm={4}>Skip survey</Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="select"
                                                    name="skipSelfAssessmentSurvey"
                                                    id="inputSkipSelfAssessmentSurvey"
                                                    value={form.skipSelfAssessmentSurvey.value}
                                                    invalid={form.skipSelfAssessmentSurvey.touched && !form.skipSelfAssessmentSurvey.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating || person.isAssigned}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    )}
                                    <FormGroup row>
                                        <Col sm={4}></Col>
                                        <Col sm={8} style={{ height: "38px", paddingTop: "10px" }}>
                                            <Input type="checkbox" name="isCandidate" id="isCandidate" checked={form.isCandidate.value} style={{ marginRight: "10px" }} disabled={!editMode || this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                            <Label for="isCandidate" check>
                                                Is Candidate
                                            </Label>
                                            &nbsp;&nbsp;
                                            <Input type="checkbox" name="isContractor" id="isContractor" checked={form.isContractor.value} style={{ marginRight: "10px" }} disabled={!editMode || this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                            <Label for="isContractor" check>
                                                Is Contractor
                                            </Label>
                                            <br />
                                            <Input type="checkbox" name="isMentorIneligible" id="isMentorIneligible" checked={form.isMentorIneligible.value} style={{ marginRight: "10px" }} disabled={!editMode || this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                            <Label for="isMentorIneligible" check>
                                                Is Mentor Ineligible
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Position</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="position" id="position" value={form.position.value ? form.position.value : ""} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Team</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="team" id="team" value={form.team.value ? form.team.value : ""} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Location</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="location" id="location" value={form.location.value ? form.location.value : ""} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Country</Label>
                                        <Col sm={8}>
                                            {this.state.loading ? (
                                                <Spinner type="grow" size="md" color="black" />
                                            ) : (
                                                <Input type="select" name="country" id="inputCountry" value={form.country.value} invalid={form.country.touched && !form.country.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                    {countries.map((country, key) => (
                                                        <option key={key} value={country.value}>
                                                            {country.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Other</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="other" id="other" value={form.other.value ? form.other.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Roles</Label>
                                        <Col sm={8}>
                                            {editMode && this.props.allowSetAdminRole ? (
                                                <React.Fragment>
                                                    <div style={{ paddingTop: "5px" }}>
                                                        <Input
                                                            type="checkbox"
                                                            name="chkRoleUser"
                                                            id="chkRoleUser"
                                                            checked={form.roles ? form.roles.value.includes("User") : false}
                                                            style={{ marginRight: "10px" }}
                                                            disabled={this.state.isUpdating}
                                                            onChange={() => this.handleChangeCheckBoxRole("User")}
                                                        />
                                                        <Label for="chkRoleUser" check>
                                                            User
                                                        </Label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Input
                                                            type="checkbox"
                                                            name="chkRoleAdmin"
                                                            id="chkRoleAdmin"
                                                            checked={form.roles ? form.roles.value.includes("Admin") : false}
                                                            style={{ marginRight: "10px" }}
                                                            disabled={this.state.isUpdating}
                                                            onChange={() => this.handleChangeCheckBoxRole("Admin")}
                                                        />
                                                        <Label for="chkRoleAdmin" check>
                                                            Admin
                                                        </Label>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <Input value={form.roles && form.roles.value ? form.roles.value.join(", ") : ""} disabled />
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Role Based Survey</Label>
                                        <Col sm={8}>
                                            {this.state.loading ? (
                                                <Spinner type="grow" size="md" color="black" />
                                            ) : (
                                                <Input
                                                    type="select"
                                                    name="roleBasedSurveyId"
                                                    id="inputRoleBasedSurvey"
                                                    value={form.roleBasedSurveyId.value}
                                                    invalid={form.roleBasedSurveyId.touched && !form.roleBasedSurveyId.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating || (form && (form.skipSelfAssessmentSurvey.value == "true" || form.skipSelfAssessmentSurvey.value == true)) || person.isAssigned}
                                                >
                                                    {jobs.map((job, key) => (
                                                        <option key={key} value={job.value}>
                                                            {job.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Fte</Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="fte"
                                                id="fte"
                                                value={form.fte.value ? form.fte.value : "1.0"}
                                                disabled={!editMode || this.state.isUpdating}
                                                invalid={(form.fte.touched && !(form.fte.valid && form.fte.isValidFormat)) || (form.fte.touched && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0))}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <Link to="#" onClick={this.handleShowMoreDetail}>
                                        {this.state.showMoreDetails ? "Show less fields" : "Show more fields"}
                                    </Link>
                                </Col>
                                {this.state.showMoreDetails && (
                                    <React.Fragment>
                                        <Col md={12} sm={12}>
                                            <FormGroup row></FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Endorsement Date</Label>
                                                <Col sm={8}>
                                                    <DatePicker
                                                        selected={validatedDate ? new Date(validatedDate) : null}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(value) => this.handleDateChange("validatedDate", value)}
                                                        disabled={!editMode || this.state.isUpdating}
                                                        className="form-control"
                                                        isClearable={!(!editMode || this.state.isUpdating)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Leave On</Label>
                                                <Col sm={8}>
                                                    <DatePicker
                                                        selected={leaveOn ? new Date(leaveOn) : null}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(value) => this.handleDateChange("leaveOn", value)}
                                                        disabled={!editMode || this.state.isUpdating}
                                                        className="form-control"
                                                        isClearable={!(!editMode || this.state.isUpdating)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Department</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="department" id="department" value={form.department.value ? form.department.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Business Unit</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="businessUnit" id="businessUnit" value={form.businessUnit.value ? form.businessUnit.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Division</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="division" id="division" value={form.division.value ? form.division.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Territory</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="territory" id="territory" value={form.territory.value ? form.territory.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Cost Centre</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="costCentre" id="costCentre" value={form.costCentre.value ? form.costCentre.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup row>
                                                <Label sm={4}>Supervisor</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="supervisor" id="supervisor" value={form.supervisor.value ? form.supervisor.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} sm={12}>
                                            <FormGroup row></FormGroup>
                                        </Col>
                                        {config && (
                                            <React.Fragment>
                                                {config && config.personMiscellaneous1Enable && (
                                                    <React.Fragment>
                                                        <Col md={6} sm={12}>
                                                            <FormGroup row>
                                                                <Label sm={4}>{config.personMiscellaneous1Caption}</Label>
                                                                <Col sm={8}>
                                                                    <Input type="text" name="miscellaneous1" id="miscellaneous1" value={form.miscellaneous1.value ? form.miscellaneous1.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                                {config && config.personMiscellaneous2Enable && (
                                                    <React.Fragment>
                                                        <Col md={6} sm={12}>
                                                            <FormGroup row>
                                                                <Label sm={4}>{config.personMiscellaneous2Caption}</Label>
                                                                <Col sm={8}>
                                                                    <Input type="text" name="miscellaneous2" id="miscellaneous2" value={form.miscellaneous2.value ? form.miscellaneous2.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                                {config && config.personMiscellaneous3Enable && (
                                                    <React.Fragment>
                                                        <Col md={6} sm={12}>
                                                            <FormGroup row>
                                                                <Label sm={4}>{config.personMiscellaneous3Caption}</Label>
                                                                <Col sm={8}>
                                                                    <Input type="text" name="miscellaneous3" id="miscellaneous3" value={form.miscellaneous3.value ? form.miscellaneous3.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                                {config && config.personMiscellaneous4Enable && (
                                                    <React.Fragment>
                                                        <Col md={6} sm={12}>
                                                            <FormGroup row>
                                                                <Label sm={4}>{config.personMiscellaneous4Caption}</Label>
                                                                <Col sm={8}>
                                                                    <Input type="text" name="miscellaneous4" id="miscellaneous4" value={form.miscellaneous4.value ? form.miscellaneous4.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                                {config && config.personMiscellaneous5Enable && (
                                                    <React.Fragment>
                                                        <Col md={6} sm={12}>
                                                            <FormGroup row>
                                                                <Label sm={4}>{config.personMiscellaneous5Caption}</Label>
                                                                <Col sm={8}>
                                                                    <Input type="text" name="miscellaneous5" id="miscellaneous5" value={form.miscellaneous5.value ? form.miscellaneous5.value : ""} disabled={!editMode || this.state.isUpdating} onChange={this.handleChange} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {!editMode && (
                            <React.Fragment>
                                {this.props.allowUpdateAccess && isAdminPerson && (
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.openPersonPageAssignments();
                                        }}
                                    >
                                        Update Access Permissions
                                    </Button>
                                )}
                                {!this.props.disableEdit && this.props.allowEdit && (
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.togleShowEdit();
                                        }}
                                    >
                                        Edit
                                    </Button>
                                )}
                                <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                                    Close
                                </Button>
                            </React.Fragment>
                        )}
                        {editMode && (
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handlePerformSave();
                                    }}
                                    disabled={!this.state.isFormValid || this.state.isUpdating || (form && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0)) || (form && form.roles && form.roles.value.length == 0)}
                                >
                                    Save
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.togleShowEdit();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Cancel
                                </Button>
                            </React.Fragment>
                        )}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personsError: state.adminPerson.error,
        isUpdating: state.adminPerson.isUpdating,
        countries: state.country.countries,
        countryLoading: state.country.loading,
        jobsLoading: state.job.loading,
        jobs: state.job.jobs,
        resetPasswordSent: state.adminPerson.resetPasswordSent,
        config: state.config.config,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onCountry: bindActionCreators(countryActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
