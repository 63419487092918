import parse from "html-react-parser";
import React from "react";
import { connect } from "react-redux";

class ContentTranslator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const contentTranslations = this.props.contentTranslations;
        const contentTranslation = contentTranslations.find((it) => it.page == this.props.page && it.name == this.props.name);

        return parse(
            contentTranslation ? (contentTranslation.contentTextTranslation ? contentTranslation.contentTextTranslation : contentTranslation.contentText ? contentTranslation.contentText : this.props.contentText ? this.props.contentText : "") : this.props.contentText ? this.props.contentText : ""
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
        language: state.language.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentTranslator);
