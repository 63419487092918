import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavLink } from 'reactstrap';
import React, { Component, Fragment } from 'react';

import Flag from 'react-flagkit';
import { Link } from 'react-router-dom';
import { lang } from 'moment';

//import magglass from '../../assets/img/lgo/magglass.png';

const linkRightStyle = {
    padding: 0,
    marginLeft: '5px',
    marginRight: '5px',
    // width: '70px',
    fontSize: 13,
}

export class NavRight extends Component {
    static displayName = NavRight.name;

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            countryFlags: [
                {
                    "name": "(Arabic) العربية",
                    "code": "SA"
                },
                {
                    "name": "Deutsch (German)",
                    "code": "DE"
                },
                {
                    "name": "Español (Spanish)",
                    "code": "ES"
                },
                {
                    "name": "日本語 (Japanese)",
                    "code": "JP"
                },
                {
                    "name": "中国 (Chinese)",
                    "code": "CN"
                },
                {
                    "name": "Français (French)",
                    "code": "FR"
                },
                {
                    "name": "Italiano (Italian)",
                    "code": "IT"
                },
                {
                    "name": "Polskie (Polish)",
                    "code": "PL"
                },
                {
                    "name": "Русский (Russian)",
                    "code": "RU"
                },
                {
                    "name": "bahasa Indonesia (Indonesian)",
                    "code": "ID"
                },
                {
                    "name": "ไทย (Thai)",
                    "code": "TH"
                },
                {
                    "name": "US (English)",
                    "code": "US"
                },
                {
                    "name": "English",
                    "code": "GB"
                },
                {
                    "name": "Canadien français (French Canada)",
                    "code": "CA"
                },
                {
                    "name": "Português (Portuguese)",
                    "code": "PT"
                }
            ]
        };
    }

    selectLanguage = (language) => {
        this.props.setCurrentLanguage(language);
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));

    }

    render() {
        const countryFlags = this.state.countryFlags;
        let languages = this.props.languages;

        languages.map(language => {
            if (countryFlags.find(it => it.name == language.name)) {
                const countryFlag = countryFlags.find(it => it.name == language.name);
                language.code = countryFlag.code;
            }
        });

        let selectedLanguage = this.props.selectedLanguage;
        if (selectedLanguage && countryFlags.find(it => it.name == selectedLanguage.name)) {
            const countryFlag = countryFlags.find(it => it.name == selectedLanguage.name);
            selectedLanguage.code = countryFlag.code;
        }

        return (
            <Fragment>
                <div className="row align-items-top">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret style={{ fontSize: "12px" }}>
                            {selectedLanguage && <Fragment><Flag country={selectedLanguage.code} /> {selectedLanguage.name}</Fragment>}
                        </DropdownToggle>
                        <DropdownMenu>
                            {languages && languages.map(language => {
                                return <DropdownItem key={language.id} style={{ fontSize: "12px" }} onClick={() => this.selectLanguage(language)}>
                                    <Flag key={`flag${language.id}`} country={language.code} /> {language.name}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="row align-items-top">
                    <NavLink tag={Link} className="color-dark text-wrap font-weight-bold d-inline-block" style={linkRightStyle} to={{ pathname: "https://skillstx.com/wisdom/" }} target="_blank">Wisdom & Support</NavLink>
                    {/* {!this.props.isAuthenticated && <NavLink tag={Link} className="color-dark text-wrap font-weight-bold d-inline-block" style={linkRightStyle} to="/">About & Contact</NavLink>}
                {!this.props.isAuthenticated && <a href="#" className="align-bottom pt-1"><img src={process.env.PUBLIC_URL + "/assets/img/lgo/magglass.png"} alt="MagGlass" width="31" height="31" /></a>} */}
                </div>
            </Fragment>
        );
    }
}