import AdminAdminRoleAPI from "./../../../common/api/adminservice/AdminAdminRoleService";
import * as actionTypes from "./adminActionTypes";

export const getAdminRolesStart = () => {
    return {
        type: actionTypes.GET_ADMIN_ROLES_START,
    };
};

export const getAdminRolesSuccess = (adminRoles) => {
    return {
        type: actionTypes.GET_ADMIN_ROLES_SUCCESS,
        adminRoles,
    };
};

export const getAdminRolesFail = (error) => {
    return {
        type: actionTypes.GET_ADMIN_ROLES_FAIL,
        error: error,
    };
};

export const getAdminRoles = () => {
    return (dispatch) => {
        dispatch(getAdminRolesStart());

        return AdminAdminRoleAPI.getAdminRoles()
            .then((res) => {
                dispatch(getAdminRolesSuccess(res));
            })
            .catch((err) => {
                dispatch(getAdminRolesFail(err));
            });
    };
};

export const getAdminRolePageAssignmentsStart = () => {
    return {
        type: actionTypes.GET_ADMIN_ROLE_PAGE_ASSIGNMENTS_START,
    };
};

export const getAdminRolePageAssignmentsSuccess = (pageAssignments) => {
    return {
        type: actionTypes.GET_ADMIN_ROLE_PAGE_ASSIGNMENTS_SUCCESS,
        pageAssignments,
    };
};

export const getAdminRolePageAssignmentsFail = (error) => {
    return {
        type: actionTypes.GET_ADMIN_ROLE_PAGE_ASSIGNMENTS_FAIL,
        error: error,
    };
};

export const getAdminRolePageAssignments = (adminRoleId) => {
    return (dispatch) => {
        dispatch(getAdminRolePageAssignmentsStart());

        return AdminAdminRoleAPI.getAdminRolePageAssignments(adminRoleId)
            .then((res) => {
                dispatch(getAdminRolePageAssignmentsSuccess(res));
            })
            .catch((err) => {
                dispatch(getAdminRolePageAssignmentsFail(err));
            });
    };
};

export const createAdminRoleStart = () => {
    return {
        type: actionTypes.ADD_ADMIN_ROLE_START,
    };
};

export const createAdminRoleSuccess = (adminRole) => {
    return {
        type: actionTypes.ADD_ADMIN_ROLE_SUCCESS,
        adminRole,
    };
};

export const createAdminRoleFail = (error) => {
    return {
        type: actionTypes.ADD_ADMIN_ROLE_FAIL,
        error: error,
    };
};

export const createAdminRole = (adminRole) => {
    return (dispatch) => {
        dispatch(createAdminRoleStart());

        return AdminAdminRoleAPI.createAdminRolePageAssignments(adminRole)
            .then((res) => {
                dispatch(createAdminRoleSuccess(res));
            })
            .catch((err) => {
                dispatch(createAdminRoleFail(err));
            });
    };
};

export const updateAdminRolePageAssignmentsStart = () => {
    return {
        type: actionTypes.UPDATE_ADMIN_ROLE_PAGE_ASSIGNMENTS_START,
    };
};

export const updateAdminRolePageAssignmentsSuccess = () => {
    return {
        type: actionTypes.UPDATE_ADMIN_ROLE_PAGE_ASSIGNMENTS_SUCCESS,
    };
};

export const updateAdminRolePageAssignmentsFail = (error) => {
    return {
        type: actionTypes.UPDATE_ADMIN_ROLE_PAGE_ASSIGNMENTS_FAIL,
        error: error,
    };
};

export const updateAdminRolePageAssignments = (adminRoleId, adminRole) => {
    return (dispatch) => {
        dispatch(updateAdminRolePageAssignmentsStart());

        return AdminAdminRoleAPI.updateAdminRolePageAssignments(adminRoleId, adminRole)
            .then((res) => {
                dispatch(updateAdminRolePageAssignmentsSuccess(res));
            })
            .catch((err) => {
                dispatch(updateAdminRolePageAssignmentsFail(err));
            });
    };
};

export const deleteAdminRoleStart = () => {
    return {
        type: actionTypes.DELETE_ADMIN_ROLE_START,
    };
};

export const deleteAdminRoleSuccess = (adminRoleId) => {
    return {
        type: actionTypes.DELETE_ADMIN_ROLE_SUCCESS,
        adminRoleId,
    };
};

export const deleteAdminRoleFail = (error) => {
    return {
        type: actionTypes.DELETE_ADMIN_ROLE_FAIL,
        error: error,
    };
};

export const deleteAdminRole = (adminRoleId) => {
    return (dispatch) => {
        dispatch(deleteAdminRoleStart());

        return AdminAdminRoleAPI.deleteAdminRole(adminRoleId)
            .then((res) => {
                dispatch(deleteAdminRoleSuccess(adminRoleId));
            })
            .catch((err) => {
                dispatch(deleteAdminRoleFail(err));
            });
    };
};

export const renameAdminRoleStart = () => {
    return {
        type: actionTypes.RENAME_ADMIN_ROLE_START,
    };
};

export const renameAdminRoleSuccess = (adminRoleId, name) => {
    return {
        type: actionTypes.RENAME_ADMIN_ROLE_SUCCESS,
        adminRoleId,
        name: name,
    };
};

export const renameAdminRoleFail = (error) => {
    return {
        type: actionTypes.RENAME_ADMIN_ROLE_FAIL,
        error: error,
    };
};

export const renameAdminRole = (adminRoleId, name) => {
    return (dispatch) => {
        dispatch(renameAdminRoleStart());

        return AdminAdminRoleAPI.renameAdminRole(adminRoleId, name)
            .then((res) => {
                dispatch(renameAdminRoleSuccess(adminRoleId, name));
            })
            .catch((err) => {
                dispatch(renameAdminRoleFail(err));
            });
    };
};
